import {
    UPDATE_SHOW_CREATE_NEW_REPORT,
    UPDATE_SHOW_CLONE_REPORT
} from '../constants/ActionTypes';

const initialState = {
    showCloneReport: false,
    showCreateNewReport: false,
    reportToClone: null
};

export default function wizard(state = initialState, action) {
    switch (action.type) {
        case UPDATE_SHOW_CREATE_NEW_REPORT:
            return {
                ...state,
                showCreateNewReport: action.open
            };
        case UPDATE_SHOW_CLONE_REPORT:
            return {
                ...state,
                showCloneReport: action.open,
                reportToClone: action.reportToClone
            };
        default:
            return state;
    }
}