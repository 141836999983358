import {
    UPDATE_FEATURE_FLAGS
} from '../constants/ActionTypes';

// define initial states for the feature flags. If the default is true, we may
// want to remove it from the codebase.
const initialState = {
    createReportFromScratch: false,
    shareMyTabView: false,
    showNewDashboard: false,
    'xi-pro-reporting-app-settings': false
};

export default function featureFlags(state = initialState, action) {
    switch (action.type) {
        case UPDATE_FEATURE_FLAGS:
            return { ...state, ...action.featureFlags };
        default:
            return state;
    }
}