/**
 * The screen width options
 * xSmall1 - < 480
 * xSmall2 - 480 - 600
 * small1 - 600 - 840
 * small2 - 840 - 960
 * medium1 - 960 - 1024
 * medium2 - 1024 - 1280
 * large1 - 1280 - 1440
 * large2 - 1440 - 1600
 * large3 - 1600 - 1920
 * xLarge - > 1920
 */

const SCREEN_MIN_WIDTHS = {
    xSmall1: 0,
    xSmall2: 480,
    small1: 600,
    small2: 840,
    medium1: 960,
    medium2: 1024,
    large1: 1280,
    large2: 1440,
    large3: 1600,
    xLarge: 1920
};

const SCREEN_BREAKPOINTS = {
    xSmall1: 'xSmall1',
    xSmall2: 'xSmall2',
    small1: 'small1',
    small2: 'small2',
    medium1: 'medium1',
    medium2: 'medium2',
    large1: 'large1',
    large2: 'large2',
    large3: 'large3',
    xLarge: 'xLarge'
};

const screenWidths = {
    xSmall1: false,
    xSmall2: false,
    small1: false,
    small2: false,
    medium1: false,
    medium2: false,
    large1: false,
    large2: false,
    large3: false,
    xLarge: false
};

const setBreakpoint = (minWidth, maxWidth, breakpoint, screenWidth) => {
    screenWidths[breakpoint] =
        ((minWidth <= screenWidth) && (screenWidth < maxWidth));
};

const checkDevice = (screenWidth) => {
    // Set full screen widths
    setBreakpoint(
        SCREEN_MIN_WIDTHS.xSmall1,
        SCREEN_MIN_WIDTHS.xSmall2,
        SCREEN_BREAKPOINTS.xSmall1,
        screenWidth
    );

    setBreakpoint(
        SCREEN_MIN_WIDTHS.xSmall2,
        SCREEN_MIN_WIDTHS.small1,
        SCREEN_BREAKPOINTS.xSmall2,
        screenWidth
    );

    setBreakpoint(
        SCREEN_MIN_WIDTHS.small1,
        SCREEN_MIN_WIDTHS.small2,
        SCREEN_BREAKPOINTS.small1,
        screenWidth
    );

    setBreakpoint(
        SCREEN_MIN_WIDTHS.small2,
        SCREEN_MIN_WIDTHS.medium1,
        SCREEN_BREAKPOINTS.small2,
        screenWidth
    );

    setBreakpoint(
        SCREEN_MIN_WIDTHS.medium1,
        SCREEN_MIN_WIDTHS.medium2,
        SCREEN_BREAKPOINTS.medium1,
        screenWidth
    );

    setBreakpoint(
        SCREEN_MIN_WIDTHS.medium2,
        SCREEN_MIN_WIDTHS.large1,
        SCREEN_BREAKPOINTS.medium2,
        screenWidth
    );

    setBreakpoint(
        SCREEN_MIN_WIDTHS.large1,
        SCREEN_MIN_WIDTHS.large2,
        SCREEN_BREAKPOINTS.large1,
        screenWidth
    );

    setBreakpoint(
        SCREEN_MIN_WIDTHS.large2,
        SCREEN_MIN_WIDTHS.large3,
        SCREEN_BREAKPOINTS.large2,
        screenWidth
    );

    setBreakpoint(
        SCREEN_MIN_WIDTHS.large3,
        SCREEN_MIN_WIDTHS.xLarge,
        SCREEN_BREAKPOINTS.large3,
        screenWidth
    );

    setBreakpoint(
        SCREEN_MIN_WIDTHS.xLarge,
        Infinity,
        SCREEN_BREAKPOINTS.xLarge,
        screenWidth
    );

    const isMobile = screenWidths.xSmall1 || screenWidths.xSmall2;
    const isTablet = screenWidths.small1 || screenWidths.small2;
    const isComputer = !isMobile && !isTablet;
    const device = { isMobile, isTablet, isComputer };

    return device;
};

export default checkDevice;