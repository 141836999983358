import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import alert from './alert';
import auth from './auth';
import featureFlags from './featureFlags';
import report from './report';
import screenSize from './screenSize';
import user from './user';
import wizard from './wizard';

// eslint-disable-next-line arrow-body-style
export default (history) => combineReducers({
    router: connectRouter(history),
    alert,
    auth,
    featureFlags,
    report,
    screenSize,
    user,
    wizard
});
