import { UPDATE_DEVICE } from '../constants/ActionTypes';

const initialState = {
    device: {
        isComputer: false,
        isTablet: false,
        isMobile: false
    }
};

export default function screenSize(state = initialState, action) {
    switch (action.type) {
        case UPDATE_DEVICE:
            return {
                ...state,
                device: action.device
            };
        default:
            return state;
    }
}
