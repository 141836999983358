import React from 'react';
import PropTypes from 'prop-types';

import { InputField, IconButton } from '@inmoment/the-kitchen';

import './CustomReportTab.scss';

class CreateCustomReportTab extends React.Component {
    updateTabName = (e) => {
        const { onUpdate, order } = this.props;
        onUpdate(order, { name: e.target.value });
    }

    updateDashboardId = (e) => {
        const { onUpdate, order } = this.props;
        onUpdate(order, { dashboardId: e.target.value });
    }

    updateDisplay = () => {
        const { display, onUpdate, order } = this.props;
        onUpdate(order, { display: !display });
    }

    onDelete = () => {
        const { onUpdate, order } = this.props;
        onUpdate(order, { deleted: true });
    }

    render() {
        const {
            canEditDetails,
            dashboardId,
            dashboardIdError,
            disableVisibleToggle,
            display,
            tabId,
            tabName,
            tabNameError
        } = this.props;

        return (<div className="custom-report-wrapper">
            <div className="custom-report-name-field">
                <InputField
                    status={ (tabNameError) ? {
                        state: 'error',
                        text: tabNameError
                    } : null }
                    onChange={ this.updateTabName }
                    value={ tabName }
                    placeholder={ 'Report Name' }
                    disabled={ !canEditDetails }
                />
            </div>
            <div className="custom-report-id-field">
                <InputField
                    status={ (dashboardIdError) ? {
                        state: 'error',
                        text: dashboardIdError
                    } : null }
                    disabled={ Boolean(tabId) || !canEditDetails }
                    onChange={ this.updateDashboardId }
                    value={ dashboardId }
                    placeholder={ 'ID Number' }
                />
            </div>
            <div className="custom-report-action-buttons">
                <IconButton
                    icon={ display ? 'visibility' : 'visibility_off' }
                    onClick={ this.updateDisplay }
                    disabled={ disableVisibleToggle || !canEditDetails }
                />
                <IconButton
                    icon={ 'trash' }
                    onClick={ this.onDelete }
                    disabled={ !canEditDetails }
                />
            </div>
        </div>
        );
    }
}

CreateCustomReportTab.defaultProps = {
    disableVisibleToggle: false
};

CreateCustomReportTab.propTypes = {
    canEditDetails: PropTypes.bool.isRequired,
    dashboardId: PropTypes.string,
    dashboardIdError: PropTypes.string,
    disableVisibleToggle: PropTypes.bool,
    display: PropTypes.bool.isRequired,
    onUpdate: PropTypes.func.isRequired,
    order: PropTypes.number.isRequired,
    reportTabs: PropTypes.array.isRequired,
    tabId: PropTypes.string.isRequired,
    tabName: PropTypes.string.isRequired,
    tabNameError: PropTypes.string.isRequired
};

export default CreateCustomReportTab;
