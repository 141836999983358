import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actionCreators from '../../actions';
import { bindActionCreators } from 'redux';

import {
    DefaultButton,
    Drawer,
    InputField,
    ToggleSwitch,
    XIAnimation
} from '@inmoment/the-kitchen';

import './CreateNewReport.scss';

const idNumberRegex = /^[1-9][0-9]*$/;
const whitespaceOnlyRegex = /^\s+$/;
const whitespaceRunRegex = /\s\s+/;
const leadingOrTrailingWhitespaceRegex = /^\s|\s$/;

function CreateNewReport(props) {
    const {
        actions: {
            createLinkedReport,
            createTab
        },
        isLoadingCreateReport,
        onClose,
        open,
        permissions,
        program,
        tabs
    } = props;

    const [disabled, setDisabled] = React.useState(true);
    const [isLinkedReport, setIsLinkedReport] = React.useState(false);
    const [linkedId, setLinkedId] = React.useState(null);
    const [linkedIdErrorStatus, setLinkedIdErrorStatus] = React.useState(null);
    const [reportName, setReportName] = React.useState(null);
    const [
        reportNameErrorStatus,
        setReportNameErrorStatus
    ] = React.useState(null);

    React.useEffect(() => {
        if (isLoadingCreateReport) {
            setDisabled(true);
        }
    }, [isLoadingCreateReport]);

    React.useEffect(() => {
        if (typeof(reportName) === 'string') {
            let error;

            if (reportName.length < 1) {
                error = 'Name must be at least 1 character long!';
            }
            else if (whitespaceOnlyRegex.test(reportName)) {
                error = 'Name must contain 1 or more characters and/or numbers!';
            }
            else if (whitespaceRunRegex.test(reportName)) {
                error = 'Name cannot contain more than 1 space in a row!';
            }
            else if (leadingOrTrailingWhitespaceRegex.test(reportName)) {
                error = 'Name cannot contain leading or trailing spaces!';
            }
            else if (reportName.length > 50) {
                error = 'Name exceeds maximum character limit!';
            }
            else if (tabs.some((tab) => { return tab.name === reportName; })) {
                error = 'Name must be unique!';
            }

            if (error) {
                setReportNameErrorStatus({
                    state: 'error',
                    text: error
                });
            }
            else {
                setReportNameErrorStatus(null);
            }
        }
    }, [reportName]);

    React.useEffect(() => {
        if (!isLinkedReport) {
            setLinkedId(null);
            setLinkedIdErrorStatus(null);
            return;
        }

        if (typeof(linkedId) === 'string') {
            let error;

            if (!idNumberRegex.test(linkedId)) {
                error = 'ID Number must be a number greater than 0.';
            }
            else if (tabs.some((tab) => {
                return tab.dashboardId === linkedId;
            })) {
                error = 'ID Number must be unique';
            }

            if (error) {
                setLinkedIdErrorStatus({
                    state: 'error',
                    text: error
                });
            }
            else {
                setLinkedIdErrorStatus(null);
            }
        }
    }, [linkedId, isLinkedReport]);

    React.useEffect(() => {
        setDisabled(!reportName ||
            (isLinkedReport && !linkedId) ||
            reportNameErrorStatus ||
            linkedIdErrorStatus
        );
    }, [
        reportName,
        reportNameErrorStatus,
        isLinkedReport,
        linkedId,
        linkedIdErrorStatus
    ]);

    const reset = () => {
        setDisabled(true);
        setLinkedId(null);
        setLinkedIdErrorStatus(null);
        setIsLinkedReport(false);
        setReportName(null);
        setReportNameErrorStatus(null);
    };

    const handleClose = () => {
        reset();
        onClose();
    };

    const handleSubmit = async () => {
        if (isLinkedReport) {
            await createLinkedReport(reportName, linkedId);
        }
        else {
            await createTab(reportName);
        }

        handleClose();
    };

    const canLinkReports = permissions.includes('REPORTING_EDITOR_CREATE_REPORT') ||
        permissions.includes('REPORTING_EDITOR_CLONE_REPORT');

    return (
        <Drawer
            color={ program.cloudType }
            open={ open }
            onClose={ handleClose }
        >
            <div slot="title">
                Create New
            </div>
            <div slot="body">
                { (isLoadingCreateReport) ?
                    <div className="create-report-dialog-loading">
                        <XIAnimation
                            animation={ `${program.cloudType}_loader` }
                            loop={ true }
                        />
                    </div> :
                    <div>
                        <div className="create-report-top">
                            <div className="title">
                                Create New Report
                            </div>
                            <div className="subtitle">
                                Create a new report
                            </div>
                        </div>
                        <div className="create-report-name">
                            <div className="description">
                                <div className="title">Report Name</div>
                                <div className="subtitle">Name your report</div>
                            </div>
                            <div className="name-input">
                                <InputField
                                    value={ reportName }
                                    status={ reportNameErrorStatus }
                                    onChange={ (e) => {
                                        setReportName(e.target.value);
                                    } }
                                />
                            </div>
                        </div>
                        { canLinkReports &&
                            <div className="link-report">
                                <div className="description">
                                    <div className="title">Link Report</div>
                                    <div className="subtitle">
                                    Link your report to an existing report
                                    </div>
                                </div>
                                <div className="link-report-input">
                                    <ToggleSwitch
                                        color="orca"
                                        isToggled={ isLinkedReport }
                                        onToggle={ () => {
                                            setIsLinkedReport(!isLinkedReport);
                                        } }
                                    />
                                    { isLinkedReport &&
                                    <div className="report-id">
                                        <span className="report-id-title">Existing Report ID Number</span>
                                        <InputField
                                            value={ linkedId }
                                            className={ 'report-id-input-field' }
                                            status={ linkedIdErrorStatus }
                                            onChange={ (e) => {
                                                setLinkedId(e.target.value);
                                            } }
                                        />
                                    </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                }
            </div>
            <div slot="footer">
                <DefaultButton
                    color={ 'orca' }
                    fill={ 'text' }
                    text={ 'Cancel' }
                    hasShadows={ false }
                    onClick={ handleClose }
                />
                <DefaultButton
                    color={ 'orca' }
                    disabled= { disabled }
                    fill={ 'fill' }
                    text={ 'Done' }
                    hasShadows={ true }
                    onClick={ handleSubmit }
                    className={ 'default-button-save' }
                />
            </div>
        </Drawer>
    );
}

// eslint-disable-next-line arrow-body-style
const mapStateToProps = (state) => ({
    isLoadingCreateReport: state.report.isLoadingCreateReport,
    permissions: state.user.permissions,
    program: state.user.program,
    tabs: state.report.tabs
});

// eslint-disable-next-line arrow-body-style
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreators, dispatch)
});

CreateNewReport.propTypes = {
    actions: PropTypes.shape({
        createLinkedReport: PropTypes.func.isRequired,
        createTab: PropTypes.func.isRequired
    }).isRequired,
    isLoadingCreateReport: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    open: PropTypes.bool.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    program: PropTypes.shape({
        cloudType: PropTypes.oneOf([
            'CUSTOMER',
            'EMPLOYEE',
            'MARKETING'
        ]).isRequired
    }).isRequired,
    tabs: PropTypes.array.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewReport);
