import {
    PUSH_TAB,
    RESET_TABS,
    SET_LOADING_CREATE_REPORT,
    UNSET_LOADING_CREATE_REPORT,
    UPDATE_AUTHENTICATED,
    UPDATE_CATEGORY_MANAGEMENT_VISIBILITY,
    UPDATE_CREATE_CATEGORY_VISIBILITY,
    UPDATE_DETAILS_OF_REPORTS,
    UPDATE_FILTER_BAR_HEIGHT,
    UPDATE_FILTER_BAR_VISIBILITY,
    UPDATE_LAST_SEEN,
    UPDATE_REPORT_FILTER_STATUS,
    UPDATE_REPORT_ID,
    UPDATE_REPORT_ID_TO_EDIT,
    UPDATE_REPORTS,
    UPDATE_TAB,
    UPDATE_TAB_CHANGES,
    UPDATE_TAB_DISPLAY,
    UPDATE_TAB_FILTERS,
    UPDATE_TAB_ID,
    UPDATE_TAB_LOADING_ERROR,
    UPDATE_TABS
} from '../constants/ActionTypes';

const initialState = {
    categoryManagementVisible: false,
    createCategoryVisible: false,
    detailsOfReports: undefined,
    filterBarHeight: null,
    filterBarVisible: false,
    reportFilterStatus: 'ACTIVE',
    hasTabLoadingError: false,
    reports: {},
    reportId: undefined,
    reportIdToEdit: null,
    reportsLoaded: false,
    tabs: [],
    tabChanges: {},
    tabId: undefined,
    tabsLoaded: false,
    authenticated: false,
    isLoadingCreateReport: false
};

export default function report(state = initialState, action) {
    switch (action.type) {
        case PUSH_TAB:
            return { ...state,
                tabId: action.tab.id,
                tabs: [].concat(state.tabs, action.tab)
            };
        case RESET_TABS:
            return {
                ...state,
                tabs: [],
                tabsLoaded: false
            };
        case SET_LOADING_CREATE_REPORT:
            return { ...state, isLoadingCreateReport: true };
        case UNSET_LOADING_CREATE_REPORT:
            return { ...state, isLoadingCreateReport: false };
        case UPDATE_AUTHENTICATED:
            return { ...state, authenticated: action.authenticated };
        case UPDATE_CATEGORY_MANAGEMENT_VISIBILITY:
            return { ...state, categoryManagementVisible: action.isVisible };
        case UPDATE_CREATE_CATEGORY_VISIBILITY:
            return { ...state, createCategoryVisible: action.isVisible };
        case UPDATE_DETAILS_OF_REPORTS:
            return { ...state, detailsOfReports: action.detailsOfReports };
        case UPDATE_FILTER_BAR_HEIGHT:
            return { ...state, filterBarHeight: action.height };
        case UPDATE_FILTER_BAR_VISIBILITY:
            return {
                ...state,
                filterBarVisible: action.isVisible
            };
        case UPDATE_LAST_SEEN:
            return {
                ...state,
                tabs: state.tabs.map((tab) => {
                    if (tab.id === action.tabId) {
                        return {
                            ...tab,
                            lastSeen: action.lastSeen
                        };
                    }

                    return tab;
                })
            };
        case UPDATE_REPORT_FILTER_STATUS:
            return { ...state, reportFilterStatus: action.reportFilterStatus };
        case UPDATE_REPORT_ID:
            return { ...state, reportId: action.reportId };
        case UPDATE_REPORT_ID_TO_EDIT:
            return { ...state, reportIdToEdit: action.reportId };
        case UPDATE_REPORTS:
            return { ...state, reports: action.reports, reportsLoaded: true };
        case UPDATE_TAB:
            return { ...state,
                tabs: state.tabs.map((tab) => {
                    return (action.tab &&
                        (tab.id === action.tab.id)) ?
                        action.tab :
                        tab;
                })
            };
        case UPDATE_TAB_CHANGES:
            if (action.tabChanges) {
                return {
                    ...state,
                    tabChanges: {
                        ...state.tabChanges,
                        ...action.tabChanges
                    }
                };
            }
            else {
                return { ...state, tabChanges: {} };
            }
        case UPDATE_TAB_DISPLAY:
            return {
                ...state,
                tabChanges: {
                    ...state.tabChanges,
                    [action.tabId]: {
                        ...state.tabChanges[action.tabId],
                        display: action.display
                    }
                }
            };
        case UPDATE_TAB_FILTERS:
            return {
                ...state,
                tabs: state.tabs.map((tab) => {
                    if (tab.id === action.tabId) {
                        let updatedEmbedUrl = tab.embedUrl;

                        // update the embedUrl value, if available, to include
                        // the new filter values so that when we open this tab
                        // again it includes the new values
                        if (tab.embedUrl) {
                            const embedUrlObject = new URL(tab.embedUrl);
                            const embedParamsObject = embedUrlObject.searchParams;
                            Object.entries(action.filters).forEach(([key, value]) => {
                                embedParamsObject.set(key, value);
                            });
                            updatedEmbedUrl = embedUrlObject.toString();
                        }

                        return {
                            ...tab,
                            embedUrl: updatedEmbedUrl,
                            filters: action.filters
                        };
                    }

                    return tab;
                })
            };
        case UPDATE_TAB_ID:
            return { ...state, tabId: action.id, filterBarHeight: null };
        case UPDATE_TAB_LOADING_ERROR:
            return { ...state, hasTabLoadingError: action.hasTabLoadingError };
        case UPDATE_TABS:
            return {
                ...state,
                tabs: action.tabs,
                tabsLoaded: true
            };
        default:
            return state;
    }
}
