import {
    UPDATE_ACCESS_TOKEN,
    UPDATE_PROGRAM_ID,
    UPDATE_USER_INFO
} from '../constants/ActionTypes';

const initialState = {
    accountId: undefined,
    programId: (() => {
        let programId = undefined;
        const { location: { pathname, search } } = window;

        // assume `program` (or `programs` for backward compatibility) in the
        // path should overrule `programId` as a query parameter.
        [, programId] = /\/programs?\/([a-z0-9]{25}).*/.exec(pathname) || [];

        if (!programId) {
            const searchParams = new URLSearchParams(search);
            programId = searchParams.get('programId') || undefined;
        }

        return programId;
    })(),
    program: undefined,
    programs: [],
    roles: {},
    permissions: [],
    user: {
        accessToken: '',
        authId: '',
        profilePicture: '',
        email: '',
        firstName: '',
        lastName: '',
        lastLogin: '',
        name: '',
        userLocale: 'en_US',
        role: '',
        userId: null
    }
};

export default function user(state = initialState, action) {
    switch (action.type) {
        case UPDATE_ACCESS_TOKEN: {
            const { accessToken } = action.payload;

            return {
                ...state,
                user: {
                    ...state.user,
                    accessToken
                }
            };
        }
        case UPDATE_USER_INFO: {
            const allProgramsForUser = action.payload.programs || [];
            const roles = action.payload.roles || {};

            // Update the Programs so we know which ones are "selectable",
            // which are Programs that meet the following criteria:
            //   * Includes the XI Pro Reporting application
            //   * The User has basic permission to view XI Pro Reporting
            const programs = allProgramsForUser.map((program) => {
                const { id: programId, navigationItems } = program;
                const includesReporting = navigationItems.some((navItem) => {
                    return (navItem.app &&
                            (navItem.app.uniqueId === 'reporting'));
                });
                const role = roles[programId];
                const hasViewPermission = role &&
                    role.permissions.includes('REPORTING_VIEWER_VIEW');

                program.selectable = includesReporting && hasViewPermission;

                return program;
            });

            const programId = state.programId;

            const program = programId && programs.find((program) => {
                return program.id === programId;
            });

            const accountId = (program) ? program.account.id : undefined;

            const role = roles[programId];
            const permissions = role ? role.permissions : [];

            return {
                ...state,
                accountId,
                programId,
                program,
                programs,
                roles,
                permissions,
                user: {
                    ...state.user,
                    ...action.payload,
                    programs: undefined,
                    roles: undefined
                }
            };
        }
        case UPDATE_PROGRAM_ID: {
            const programs = state.programs;

            const programId = action.programId;
            const program = programs.find((program) => {
                return program.id === programId;
            });

            const accountId = (program) ? program.account.id : undefined;

            const roles = state.roles;
            const role = roles[programId];
            const permissions = role ? role.permissions : [];

            return {
                ...state,
                accountId,
                programId,
                program,
                permissions
            };
        }
        default:
            return state;
    }
}
