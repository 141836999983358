import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actionCreators from '../../actions';
import { bindActionCreators } from 'redux';

import { TabBar } from '@inmoment/the-kitchen';

import './ReportTabs.scss';

function ReportTabs(props) {
    const {
        actions,
        className,
        program,
        tabChanges = {},
        tabId,
        tabs = []
    } = props;

    const reportTabsEl = React.useRef(null);
    const [visibleTabs, setVisibleTabs] = React.useState([]);

    useEffect(() => {
        setVisibleTabs(
            tabs.filter((tab) => {
                return tab.display;
            }).sort((tab1, tab2) => {
                return tab1.order - tab2.order;
            }).map((tab) => {
                return {
                    id: tab.id,
                    title: tab.name,
                    alert: !tab.lastSeen
                };
            })
        );
    }, [tabChanges, tabs]);

    const onSelect = (tab) => {
        const {
            updatePathName,
            updateTabId
        } = actions;

        updateTabId(tab.id);
        updatePathName();
    };

    return (
        <div
            className={ `report-tabs-wrapper ${className || ''}` }
            ref={ reportTabsEl }
        >
            { (visibleTabs.length > 0) &&
                <TabBar
                    activeTabId={ tabId }
                    color={ program.cloudType }
                    contrast={ 'high' }
                    data={ visibleTabs }
                    tabSpacing={ 12 }
                    maxCharsAllowed={ 25 }
                    tooltip={ {
                        direction: 6
                    } }
                    hasTransparentBackground={ true }
                    stretch={ true }
                    onTabChange={ onSelect }
                />
            }
        </div>
    );
}

// eslint-disable-next-line arrow-body-style
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreators, dispatch)
});

// eslint-disable-next-line arrow-body-style
const mapStateToProps = (state) => ({
    program: state.user.program,
    tabChanges: state.report.tabChanges,
    tabId: state.report.tabId,
    tabs: state.report.tabs
});

ReportTabs.propTypes = {
    actions: PropTypes.shape({
        updatePathName: PropTypes.func.isRequired,
        updateTabId: PropTypes.func.isRequired
    }).isRequired,
    className: PropTypes.string,
    program: PropTypes.shape({
        cloudType: PropTypes.oneOf([
            'CUSTOMER',
            'EMPLOYEE',
            'MARKETING'
        ]).isRequired
    }),
    tabChanges: PropTypes.object,
    tabId: PropTypes.string,
    tabs: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            display: PropTypes.bool.isRequired,
            order: PropTypes.number.isRequired,
            lastSeen: PropTypes.string
        })
    ),
    width: PropTypes.number
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportTabs);
