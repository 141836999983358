import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actionCreators from '../../actions';
import { bindActionCreators } from 'redux';

import {
    Dropdown,
    XIIcon
} from '@inmoment/the-kitchen';

import './CategoryDetail.scss';

/** @typedef {import('@inmoment/the-kitchen').DropdownObject} DropdownObject */

function CategoryDetail(props) {
    const {
        canReorder,
        className,
        dataSource = 'n/a',
        id,
        name,
        onActivate,
        onArchive,
        onEdit,
        reportsCount = 0,
        rolesCount = 0,
        status,
        template = 'n/a',
        canUpdateStatus,
        permissions
    } = props;

    const onSelect = (action) => {
        const { id: selectedAction } = action;

        if (selectedAction === 'edit') {
            onEdit(id);
        }

        if (canUpdateStatus) {
            if (selectedAction === 'archive') {
                onArchive(id);
            }
            else if (selectedAction === 'activate') {
                onActivate(id);
            }
        }
    };

    /** @type {DropdownObject[]} */
    const actions = [];

    if (['ACTIVE', 'ARCHIVED'].includes(status)) {
        if (permissions.includes('REPORTING_EDITOR_EDIT_REPORT')) {
            actions.push({ id: 'edit', title: 'Edit Category' });
        }

        if (permissions.includes('REPORTING_EDITOR_ARCHIVE_REPORT')) {
            if (status === 'ACTIVE') {
                actions.push({ id: 'archive', title: 'Archive Category', disabled: !canUpdateStatus });
            }
            else if (status === 'ARCHIVED') {
                actions.push({ id: 'activate', title: 'Activate Category', disabled: !canUpdateStatus });
            }
        }
    }

    const roleText = (rolesCount === 1) ? 'Role' : 'Roles';

    return (
        <div
            className={
                `category-detail-wrapper category-status-${
                    status.toLowerCase()
                } ${
                    className || ''
                }`
            }
        >
            <div className="category-detail-grabber">
                { (canReorder) &&
                    <XIIcon
                        icon={ 'drag_handle' }
                    />
                }
            </div>
            <div className="category-detail-summary">
                <div className="kxi-text-xsmall kxi-text-600">
                    { name }
                </div>
                <div className="kxi-text-xsmall kxi-text-300">
                    { status }
                </div>
            </div>
            <div className="category-detail-section">
                <div className="category-detail-data">
                    <div className="kxi-text-xsmall kxi-text-300">
                        Reports:
                    </div>
                    <div className="kxi-text-xsmall kxi-text-300">
                        { `${reportsCount}` }
                    </div>
                </div>
                <div className="category-detail-data">
                    <div className="kxi-text-xsmall kxi-text-300">
                        Template:
                    </div>
                    <div className="kxi-text-xsmall kxi-text-300">
                        { template }
                    </div>
                </div>
                <div className="category-detail-data">
                    <div className="kxi-text-xsmall kxi-text-300">
                        Data Source:
                    </div>
                    <div className="kxi-text-xsmall kxi-text-300">
                        { dataSource }
                    </div>
                </div>
                <div className="category-detail-data">
                    <div className="kxi-text-xsmall kxi-text-300">
                        Shared with:
                    </div>
                    <div className="kxi-text-xsmall kxi-text-300">
                        { `${rolesCount} ${roleText}` }
                    </div>
                </div>
            </div>
            { (actions.length > 0) ?
                <div className="category-detail-actions">
                    <Dropdown
                        color={ 'orca' }
                        data={ actions }
                        direction={ 5 }
                        select={ 'none' }
                        type={ 'icon' }
                        internalProps={ {
                            iconButton: {
                                icon: 'kabob',
                                hasAnimation: false
                            }
                        } }
                        onValueChange={ onSelect }
                    />
                </div> :
                null
            }
        </div>
    );
}

// eslint-disable-next-line arrow-body-style
const mapStateToProps = (state) =>  ({
    permissions: state.user.permissions
});

// eslint-disable-next-line arrow-body-style
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreators, dispatch)
});

CategoryDetail.propTypes = {
    canReorder: PropTypes.bool.isRequired,
    canUpdateStatus: PropTypes.bool,
    className: PropTypes.string,
    dataSource: PropTypes.string,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onActivate: PropTypes.func.isRequired,
    onArchive: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    reportsCount: PropTypes.number,
    rolesCount: PropTypes.number,
    status: PropTypes.oneOf([
        'ACTIVE',
        'ARCHIVED',
        'DISABLED',
        'PENDING'
    ]).isRequired,
    template: PropTypes.string,
    permissions: PropTypes.array.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(CategoryDetail);
