import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actionCreators from '../../actions';
import { bindActionCreators } from 'redux';

import { SuccessToaster } from '@inmoment/the-kitchen';

function AlertModal(props) {
    useEffect(() => {
        const timer = setTimeout(() => {
            const { updateShowSuccess } = props.actions;
            updateShowSuccess('', '', false);
        }, 5000);
        return () => { clearTimeout(timer); };
    }, [props.actions, props.isVisible]);

    return (
        <SuccessToaster
            subtitle={ props.description }
            title={ props.title }
            isVisible={ props.isVisible }
        />
    );
}

// eslint-disable-next-line arrow-body-style
const mapStateToProps = (state) => ({
    description: state.alert.success.description,
    isVisible: state.alert.success.isVisible,
    title: state.alert.success.title
});

// eslint-disable-next-line arrow-body-style
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreators, dispatch)
});

AlertModal.propTypes = {
    actions: PropTypes.shape({
        updateShowSuccess: PropTypes.func.isRequired
    }),
    description: PropTypes.string.isRequired,
    isVisible: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertModal);
