import {
    ASSIGN_TOKENS,
    LOGOUT,
    REFRESH_AUTH_TOKEN,
    SET_USER_ERROR,
    UPDATE_TOKENS
} from '../constants/AuthActionTypes';

export const initialState = {
    token: null,
    refreshToken: null,
    expires: null,
    loginError: false
};

function getExpirationDate(expiresIn) {
    const now = new Date();

    return new Date(now.setSeconds(now.getSeconds() + expiresIn));
}

export default function auth(state = initialState, action) {
    switch (action.type) {
        case ASSIGN_TOKENS:
        case UPDATE_TOKENS:
            return {
                token: action.payload['access_token'],
                refreshToken: action.payload['refresh_token'],
                expires: getExpirationDate(action.payload['expires_in']),
                loginError: false
            };

        case REFRESH_AUTH_TOKEN:
            return { ...state, token: null };

        case LOGOUT:
            return { ...initialState };

        case SET_USER_ERROR:
            return { ...initialState, loginError: true };

        default:
            return state;
    }
}

export const getToken = (state) => {
    return state.token;
};

export const getRefreshToken = (state) => {
    return state.refreshToken;
};

export const getLoginError = (state) => {
    return state.loginError;
};
