import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { useIntercom } from 'react-use-intercom';

function TopBar({
    actions: {
        logout,
        switchToProgram
    },
    accessToken,
    authId,
    children,
    homeUrl,
    programId,
    programs,
    userLocale
}) {
    const redirectToHome = (programId, profile = false) => {
        let redirectUrl = `${homeUrl}/program/${programId}/home`;

        if (profile) {
            redirectUrl += '/profile';
        }

        window.location.assign(redirectUrl);
    };

    useEffect(() => {
        // if on mount programId is defined, but the Program is not selectable,
        // we can assume that the programId was set by the User, in which
        // case we need to redirect to the XI Home Page for the Program.
        if (programId) {
            const program = programs.find((program) => {
                return (program.id === programId);
            });

            if (!(program && program.selectable)) {
                redirectToHome(programId);
            }
        }
    });

    const topBarEl = useRef(null);
    const [firstProgramSelect, setFirstProgramSelect] = useState(true);
    const {
        startTour
    } = useIntercom();

    const logoutHandler = () => {
        logout();
    };

    const navigateToProfilePageHandler = () => {
        redirectToHome(programId, true);
    };

    const programSelectedHandler = (event) => {
        const nextProgramId = event.detail;
        const nextProgram = programs.find((program) => {
            return (program.id === nextProgramId);
        });

        if (!nextProgram) {
            Sentry.captureMessage(`Switching to Program ${nextProgramId} which was not found`, 'error');
            redirectToHome(nextProgramId);
            return;
        }

        if (nextProgram.selectable) {
            // if the selected Program is "selectable" (the Program includes
            // the XI Pro Reporting app AND the User's Role has permission to
            // view XI Pro Reporting) then switch to it
            switchToProgram(nextProgramId);
        }
        else if (firstProgramSelect && !programId) {
            // this is the first time the programSelectedHandler has been fired
            // and there is not a previously set Program ID (e.g. the url for
            // XI Pro Reporting did not include a query parameter setting the
            // Program ID or the url path did not set the Program ID). In this
            // case, we are letting the XI Top Bar select the most recently
            // visited Program. However, there's a caveat. The most recently
            // visited Program might not include the XI Pro Reporting app! In
            // that case, we need to look up a suitable alternative Program. If
            // none are found, then redirect the user to the XI Home Page.

            const alternateProgram = programs.find((program) => {
                return program.selectable;
            });

            if (alternateProgram) {
                // we found an alternate Program, so switch to that one
                switchToProgram(alternateProgram.id);
            }
            else {
                // no suitable alternate Programs were found, redirect to the
                // XI Home Page for the most recently visited Program
                redirectToHome(nextProgramId);
                return;
            }
        }
        else {
            // the Program was one selected by the User, so redirect to the
            // XI Home Page for that Program
            redirectToHome(nextProgramId);
            return;
        }

        if (firstProgramSelect) {
            setFirstProgramSelect(false);
        }
    };

    const xiGuideClickedHandler = () => {
        startTour(114583);
    };

    // create a hook for adding and removing listeners for the XI Top Bar
    const useEventListener = (event, handler) => {
        useEffect(() => {
            const topBar = topBarEl.current;

            topBar.addEventListener(event, handler);

            return () => {
                topBar.removeEventListener(event, handler);
            };
        }, [event, handler]);
    };

    useEventListener('logout', logoutHandler);
    useEventListener('navigateToProfilePage', navigateToProfilePageHandler);
    useEventListener('programSelected', programSelectedHandler);
    useEventListener('xiGuideClicked', xiGuideClickedHandler);

    useEffect(() => {
        const topBar = topBarEl.current;

        topBar.accessToken = accessToken;
        topBar.authUserId = authId;
        // currentApp takes in the uniqueId value for the XI Pro Reporting app
        topBar.currentApp = 'reporting';
        topBar.locale = userLocale;
        // eslint-disable-next-line no-process-env
        topBar.version = process.env['REACT_APP_VERSION'];

        // if we do have a `programId`, set it on the XI Top Bar, otherwise let
        // the XI Top Bar select the most recently visited Program
        if (programId) {
            topBar.programId = programId;
        }
    }, [topBarEl, accessToken, authId, programId, userLocale]);

    return (
        <inmoment-element-app-top-bar ref={ topBarEl }>
            { children }
        </inmoment-element-app-top-bar>
    );
}

TopBar.propTypes = {
    actions: PropTypes.shape({
        logout: PropTypes.func.isRequired,
        switchToProgram: PropTypes.func.isRequired
    }),
    accessToken: PropTypes.string,
    authId: PropTypes.string,
    children: PropTypes.any,
    homeUrl: PropTypes.string.isRequired,
    programId: PropTypes.string,
    programs: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            account: PropTypes.shape({
                id: PropTypes.string.isRequired
            }).isRequired,
            selectable: PropTypes.bool.isRequired
        })
    ),
    userLocale: PropTypes.string.isRequired
};

export default TopBar;
