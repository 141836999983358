import React from 'react';
import PropTypes from 'prop-types';

import { DragSource, DropTarget } from 'react-dnd';

const itemSource = {
    beginDrag(props) {
        return props.getItem && props.getItem();
    }
};
const itemTarget = {
    drop(props, monitor, component) {
        if (props.onDrop) {
            return props.onDrop(props, monitor, component);
        }
    }
};

const dragFrom = (props) => { return props.dragFrom; };
const dropFrom = (props) => { return props.dropFrom; };

// eslint-disable-next-line arrow-body-style
const connectSource = (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging()
});
// eslint-disable-next-line arrow-body-style
const connectTarget = (connect, monitor) => ({
    connectDropTarget: connect.dropTarget()
});

class DnDItem extends React.Component {
    render() {
        const {
            children, style,
            connectDragSource, connectDropTarget, connectDragPreview, dragPreview, isDragging, dragDisabled,
            ...props
        } = this.props;

        if (dragPreview) {
            connectDragPreview(dragPreview);
        }

        if (dragDisabled) {
            return <div { ...props }>{ children }</div>;
        }

        return connectDragSource(connectDropTarget(
            <div { ...props } style={ { ...style, cursor: isDragging && 'move' } }>
                { children }
            </div>
        ));
    }
}

DnDItem.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    connectDragPreview: PropTypes.func.isRequired,
    connectDragSource: PropTypes.func.isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    dragDisabled: PropTypes.bool,
    dragPreview: PropTypes.node,
    isDragging: PropTypes.bool,
    style: PropTypes.any
};

/* eslint-disable new-cap */
export default DragSource(dragFrom, itemSource, connectSource)(
    DropTarget(dropFrom, itemTarget, connectTarget)(DnDItem)
);
/* eslint-enable new-cap */
