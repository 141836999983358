import React from 'react';
import PropTypes from 'prop-types';

import { EditableText, IconButton } from '@inmoment/the-kitchen';

import './CustomReportTab.scss';

class ManageCustomReportTab extends React.Component {
    updateDisplay = () => {
        const { onUpdate, order, display } = this.props;
        onUpdate(order, { display: !display });
    }

    updateTabName = (e) => {
        const { onUpdate, order } = this.props;
        onUpdate(order, { name: e.target.value });
    }

    render() {
        const {
            canEditDetails,
            disableVisibleToggle,
            display,
            tabName,
            tabNameError
        } = this.props;

        return (
            <div className="custom-report-wrapper">
                <div className="custom-report-name-field">
                    <EditableText className="kxi-text-large"
                        value={ tabName }
                        onChange={ this.updateTabName }
                        onInputEnter={ this.updateTabName }
                        status={ (tabNameError) ? {
                            state: 'error',
                            text: tabNameError
                        } : null }
                        disabled={ !canEditDetails }
                    />
                </div>
                <div className="custom-report-id-field"></div>
                <div className="custom-report-action-buttons">
                    <IconButton
                        disabled={ disableVisibleToggle || !canEditDetails }
                        icon={ display ? 'visibility' : 'visibility_off' }
                        onClick={ this.updateDisplay }
                    />
                </div>
            </div>
        );
    }
}

ManageCustomReportTab.defaultProps = {
    disableVisibleToggle: false
};

ManageCustomReportTab.propTypes = {
    canEditDetails: PropTypes.bool.isRequired,
    disableVisibleToggle: PropTypes.bool,
    display: PropTypes.bool.isRequired,
    order: PropTypes.number.isRequired,
    onUpdate: PropTypes.func.isRequired,
    tabName: PropTypes.string.isRequired,
    tabNameError: PropTypes.string.isRequired
};

export default ManageCustomReportTab;
