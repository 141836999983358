import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useIntercom } from 'react-use-intercom';

import { logout } from '@inmoment/react-auth';
import { XIAnimation } from '@inmoment/the-kitchen';

import './Logout.scss';

function Logout({
    program
}) {
    const [logoutUrl, setLogoutUrl] = useState(null);
    const {
        shutdown: shutdownIntercom
    } = useIntercom();
    const loaderPrefix = {
        'CUSTOMER': 'cx',
        'EMPLOYEE': 'ex',
        'MARKETING': 'mx'
    }[(program && program.cloudType)] || 'orca';

    const reloadPage = () => {
        window.location.href = window.location.origin;
    };

    useEffect(() => {
        async function callLogout() {
            try {
                const logoutUrl = await logout(true);

                if (logoutUrl) {
                    setLogoutUrl(logoutUrl);
                }
                else {
                    reloadPage();
                }
            }
            catch (err) {
                reloadPage();
            }
        }

        callLogout();

        return () => {
            // shutdown Intercom when this component is unmounted
            shutdownIntercom();
        };
    }, [shutdownIntercom]);

    return (
        <div className="logout">
            <h1>Logging you out</h1>
            <XIAnimation
                animation={ `${loaderPrefix}_loader` }
                loop={ true }
            />
            { logoutUrl && (
                <iframe
                    title={ 'logout iframe' }
                    src={ logoutUrl }
                    sandbox={
                        'allow-same-origin allow-scripts allow-popups allow-forms'
                    }
                    onLoad={ reloadPage }
                ></iframe>
            ) }
        </div>
    );
}

// eslint-disable-next-line arrow-body-style
const mapStateToProps = (state) => ({
    program: state.user.program
});

Logout.propTypes = {
    program: PropTypes.shape({
        cloudType: PropTypes.string
    })
};

export default connect(mapStateToProps)(Logout);
