export const PUSH_TAB = 'PUSH_TAB';
export const UPDATE_ACCESS_TOKEN = 'UPDATE_ACCESS_TOKEN';
export const UPDATE_AUTHENTICATED = 'UPDATE_AUTHENTICATED';
export const UPDATE_REPORTS = 'UPDATE_REPORTS';
export const UPDATE_REPORT_SELECTION = 'UPDATE_REPORT_SELECTION';
export const UPDATE_SHOW_ALERT = 'UPDATE_SHOW_ALERT';
export const UPDATE_SHOW_CREATE_NEW_REPORT = 'UPDATE_SHOW_CREATE_NEW_REPORT';
export const UPDATE_SHOW_CLONE_REPORT = 'UPDATE_SHOW_CLONE_REPORT';
export const UPDATE_TAB_ID = 'UPDATE_TAB_ID';
export const UPDATE_TABS = 'UPDATE_TABS';
export const UPDATE_TAB = 'UPDATE_TAB';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const UPDATE_PROGRAM_ID = 'UPDATE_PROGRAM_ID';
export const UPDATE_FEATURE_FLAGS = 'UPDATE_FEATURE_FLAGS';
export const UPDATE_TAB_CHANGES = 'UPDATE_TAB_CHANGES';
export const UPDATE_TAB_DISPLAY = 'UPDATE_TAB_DISPLAY';
export const SET_LOADING_CREATE_REPORT = 'SET_LOADING_CREATE_REPORT';
export const UNSET_LOADING_CREATE_REPORT = 'UNSET_LOADING_CREATE_REPORT';
export const RESET_TABS = 'RESET_TABS';
export const UPDATE_REPORT_ID = 'UPDATE_REPORT_ID';
export const UPDATE_SNACK_BAR = 'UPDATE_SNACK_BAR';
export const UPDATE_SHOW_SUCCESS = 'UPDATE_SHOW_SUCCESS';
export const UPDATE_TAB_FILTERS = 'UPDATE_TAB_FILTERS';
export const UPDATE_TAB_LOADING_ERROR = 'UPDATE_TAB_LOADING_ERROR';
export const UPDATE_DETAILS_OF_REPORTS = 'UPDATE_DETAILS_OF_REPORTS';
export const UPDATE_CREATE_CATEGORY_VISIBILITY = 'UPDATE_CREATE_CATEGORY_VISIBILITY';
export const UPDATE_REPORT_ID_TO_EDIT = 'UPDATE_REPORT_ID_TO_EDIT';
export const UPDATE_CATEGORY_MANAGEMENT_VISIBILITY = 'UPDATE_CATEGORY_MANAGEMENT_VISIBILITY';
export const UPDATE_REPORT_FILTER_STATUS = 'UPDATE_REPORT_FILTER_STATUS';
export const UPDATE_LAST_SEEN = 'UPDATE_LAST_SEEN';
export const UPDATE_DEVICE = 'UPDATE_DEVICE';
export const UPDATE_FILTER_BAR_HEIGHT = 'UPDATE_FILTER_BAR_HEIGHT';
export const UPDATE_FILTER_BAR_VISIBILITY = 'UPDATE_FILTER_BAR_VISIBILITY';