import React from 'react';
import ReactDOM from 'react-dom';
import * as FullStory from '@fullstory/browser';
import * as Sentry from '@sentry/browser';
import SentryFullStory from '@sentry/fullstory';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware, ConnectedRouter } from 'connected-react-router';
import thunk from 'redux-thunk';
import { IntercomProvider } from 'react-use-intercom';

import rootReducer from './reducers';

import '@inmoment/the-kitchen/font/kitchen-icons.scss';
import '@inmoment/the-kitchen/themes/xi.css';
import '@inmoment/the-kitchen/style.css';

import './index.scss';

import App from './App';

import { init as initAuthService } from '@inmoment/react-auth';

const { ProReporting = {} } = window;

const INTERCOM_APP_ID = ProReporting.intercom && ProReporting.intercom.appId;

const { fullstory: fullstoryConfig = {} } = ProReporting;

// Set the app host to override the relay proxy setting.
// This setting is not exposed through the library.
window._fs_app_host = fullstoryConfig.appHostUrl;

FullStory.init({
    ...fullstoryConfig,
    devMode: (ProReporting.environment === 'local')
});
FullStory.setUserVars({
    app: 'reporting',
    environment: ProReporting.environment
});
const { sentry: sentryConfig = {} } = ProReporting;
Sentry.init({
    debug: (ProReporting.environment === 'local'),
    dsn: sentryConfig.dsn,
    environment: ProReporting.environment,
    integrations: sentryConfig.orgSlug && [
        new SentryFullStory(sentryConfig.orgSlug)
    ],
    // eslint-disable-next-line no-process-env
    release: process.env['REACT_APP_VERSION'],
    beforeSend: (ProReporting.environment === 'local') ? (event) => {
        let logFn;

        // Sentry event levels do not match up with console method names
        switch (event.level) {
            case 'fatal':
            case 'error':
                logFn = 'error';
                break;
            case 'warning':
                logFn = 'warn';
                break;
            case 'info':
                logFn = 'info';
                break;
            case 'debug':
                logFn = 'debug';
                break;
            default:
                logFn = 'log';
        }

        // eslint-disable-next-line no-console
        console[logFn]('[Sentry] Event:', event);
        return null;
    } : undefined
});
const history = createBrowserHistory();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
    rootReducer(history),
    undefined,
    composeEnhancers(
        applyMiddleware(
            thunk,
            routerMiddleware(history)
        )
    )
);

initAuthService({
    onSingleLogout: () => {
        // redirect to the main page, which (since there won't be a session
        // anymore) should redirect to the login page.
        window.location.assign(window.location.origin);
    }
});

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'IBM Plex Sans',
            'sans-serif'
        ].join(',')
    }
});

function Root() {
    return (
        <Provider store={ store }>
            <MuiThemeProvider theme={ theme }>
                <ConnectedRouter history={ history }>
                    <IntercomProvider appId={ INTERCOM_APP_ID }>
                        <App />
                    </IntercomProvider>
                </ConnectedRouter>
            </MuiThemeProvider>
        </Provider>
    );
}

ReactDOM.render(<Root />, document.getElementById('root'));
