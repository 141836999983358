import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import * as actionCreators from '../../actions';
import { bindActionCreators } from 'redux';
import { withResizeDetector } from 'react-resize-detector';

import AddOrEditCategory from '../AddOrEditCategory';
import CloneReport from '../CloneReport';
import CreateNewReport from '../CreateNewReport';
import DashboardHolder from '../DashboardHolder';
import EmptyPage from '../EmptyPage';
import ManageCategories from '../ManageCategories';
import ReportTabs from '../ReportTabs';
import ManageReports from '../ManageReports';
import checkDevice from '../../checkDevice';

import {
    AppTitleBar,
    Dropdown,
    IconButton,
    XIAnimation
} from '@inmoment/the-kitchen';

import './Main.scss';

const styles = (theme) => {
    return {
        root: {
            flexGrow: 1,
            backgroundColor: '#fff'
        },
        filter: {
            marginLeft: '10px'
        },
        flex: {
            flexGrow: 1
        }
    };
};

class MainTabs extends React.Component {
    state = {
        showTabsEditor: false,
        showCloneReport: false,
        ready: false,
        reportName: ''
    };

    componentDidUpdate(prevProps, prevState) {
        const { reportName } = this.state;
        const { reportName: prevReportName } = prevState;

        const { width } = this.props;
        const { width: prevWidth } = prevProps;

        if (reportName !== prevReportName) {
            this.updateReportNameError();
        }

        if (width !== prevWidth) {
            this.props.actions.updateDevice(checkDevice(width));
        }
    }

    showCreateNewReportModal = () => {
        const { updateShowCreateNewReport } = this.props.actions;
        updateShowCreateNewReport(true);
    }

    showCloneReportModal = () => {
        const { updateShowCloneReport } = this.props.actions;
        let reportToClone;

        const { tabId, tabs } = this.props;
        const report = tabs.find((tab) => {
            return tab.id === tabId;
        });

        if (report) {
            reportToClone = {
                id: report.id,
                name: report.name
            };

            updateShowCloneReport(true, reportToClone);
        }
    }

    hideCreateNewReport = () => {
        const { updateShowCreateNewReport } = this.props.actions;
        updateShowCreateNewReport(false);
    };

    hideCloneReport  = () => {
        const { updateShowCloneReport } = this.props.actions;
        updateShowCloneReport(false, null);
    };

    showTabsEditorModal = () => {
        this.setState({ showTabsEditor: true });
    };

    hideTabsEditorModal = () => {
        this.setState({ showTabsEditor: false });
    };

    showTabsDropdown = (event) => {
        this.setState({ tabsDropdownEl: event.currentTarget });
    }

    hideTabsDropdownModal = (event) => {
        this.setState({ tabsDropdownEl: null });
    }

    selectTabsDropDown = (tabId) => {
        const { updateTabId, updatePathName } = this.props.actions;
        updateTabId(tabId);
        updatePathName();
        this.hideTabsDropdownModal();
    }

    showReportCreator = () => {
        const {
            updateCreateCategoryVisibility,
            updateCategoryManagementVisibility
        } = this.props.actions;

        updateCreateCategoryVisibility(true);
        updateCategoryManagementVisibility(false);
    }

    hideCategoryCreator = () => {
        const {
            updateCreateCategoryVisibility,
            updateReportIdToEdit
        } = this.props.actions;

        this.setState({ ready: false });

        updateCreateCategoryVisibility(false);
        updateReportIdToEdit(null);
        this.clearReportName();
    }

    clearReportName() {
        this.setState({ reportName: '' });
    }

    selectReport = (report) => {
        const {
            fetchTabs,
            resetTabs,
            updatePathName,
            updateReportId
        } = this.props.actions;

        updateReportId(report.id);
        updatePathName();

        if (report.id) {
            resetTabs();
            fetchTabs();
        }
    }

    showCategoryManagement = () => {
        const { updateCategoryManagementVisibility } = this.props.actions;
        updateCategoryManagementVisibility(true);
    }

    goToManageCategories = () => {
        this.hideCategoryCreator();
        this.showCategoryManagement();
    };

    hideCategoryManagement = () => {
        const { updateCategoryManagementVisibility } = this.props.actions;
        updateCategoryManagementVisibility(false);
    }

    createCategory = async () => {
        try {
            const { detailsOfReports } = this.props;
            if (!detailsOfReports) {
                const { fetchDetailsOfReports } = this.props.actions;
                await fetchDetailsOfReports();
            }

            this.setState({ ready: true });
        }
        catch (err) {
            //no-op
        }

        const { updateReportIdToEdit } = this.props.actions;
        updateReportIdToEdit(null);
        this.clearReportName();
        this.showReportCreator();
    }

    editCategory = (reportIdToEdit) => {
        const { updateReportIdToEdit } = this.props.actions;
        updateReportIdToEdit(reportIdToEdit);
        this.showReportCreator();
    }

    canManageCategories() {
        const { permissions } = this.props;
        const managePermissions = [
            'REPORTING_EDITOR_CREATE_REPORT',
            'REPORTING_EDITOR_EDIT_REPORT',
            'REPORTING_EDITOR_ARCHIVE_REPORT'
        ];
        return  managePermissions.some((permission) => {
            return permissions.includes(permission);
        });
    }

    generateReportActionButtons() {
        if (this.canManageCategories()) {
            return {
                primary: {
                    text: 'Manage Categories',
                    onClick: this.showCategoryManagement
                }
            };
        }
        else {
            return undefined;
        }
    }

    updateReportNameError() {
        const { ready, reportName } = this.state;
        const { reportIdToEdit } = this.props;

        if (!ready) {
            return;
        }

        const reportNameError =
            this.validateReportName(reportName, reportIdToEdit);
        this.setState({ reportNameError });
    }

    validateReportName = (reportName, reportId) => {
        const { detailsOfReports } = this.props;

        if (reportName.length < 1) {
            return 'Name length less than minimum character limit.';
        }

        if (reportName.length > 50) {
            return 'Name exceeds maximum character limit.';
        }

        const isNotUnique = detailsOfReports.some((report) => {
            return ((report.name === reportName) && (reportId !== report.id));
        });

        if (isNotUnique) {
            return 'Name must be unique.';
        }

        return null;
    }

    tabActionMenuObjects() {
        const { tabs, permissions, shareMyTabView } = this.props;
        const tabsExist = tabs && (tabs.length > 0);
        const menuObjects = [];

        if (tabsExist &&
            permissions.includes('REPORTING_EDITOR_EDIT_REPORTTAB')) {
            menuObjects.push({ id: 'edit', title: 'Edit My Reports' });
        }
        if (permissions.includes('REPORTING_EDITOR_CREATE_REPORTTAB')) {
            menuObjects.push({ id: 'create', title: 'Create a New Report' });
        }
        if (tabsExist &&
            permissions.includes('REPORTING_EDITOR_CLONE_REPORTTAB')) {
            menuObjects.push({ id: 'clone', title: 'Clone this Report' });
        }
        if (tabsExist &&
            shareMyTabView &&
            permissions.includes('REPORTING_EDITOR_SHARE_REPORTTAB')) {
            menuObjects.push({ id: 'share', title: 'Share Report' });
        }
        return menuObjects;
    }

    onTabAction = (action) => {
        const actionValue = action.id;

        if (actionValue === 'edit') {
            this.showTabsEditorModal();
        }
        else if (actionValue === 'create') {
            this.showCreateNewReportModal();
        }
        else if (actionValue === 'clone') {
            this.showCloneReportModal();
        }
        else if (actionValue === 'share') {
            // TO-DO: Share a ReportTab
        }
    }

    onToggleFilterBarVisibility = () => {
        const {
            actions: {
                updateFilterBarVisibility
            },
            filterBarVisible
        } = this.props;

        updateFilterBarVisibility(
            !filterBarVisible
        );
    }

    render() {
        const {
            categoryManagementVisible,
            createCategoryVisible,
            device,
            hasTabLoadingError,
            filterBarHeight,
            filterBarVisible,
            permissions,
            program,
            reportId,
            reportIdToEdit,
            reports,
            reportsLoaded,
            reportToClone,
            showCloneReport,
            showCreateNewReport,
            showNewDashboard,
            tabs,
            tabsLoaded
        } = this.props;

        if (!program) {
            return (
                <div className="viewport-rest loading">
                    <XIAnimation
                        animation={ 'orca_loader' }
                        loop={ true }
                    />
                </div>
            );
        }

        const {
            reportName,
            showTabsEditor
        } = this.state;
        const canManageCategories = this.canManageCategories();

        const canCreateOrEditCategory =
            permissions.includes('REPORTING_EDITOR_CREATE_REPORT') ||
                permissions.includes('REPORTING_EDITOR_EDIT_REPORT');

        const categories = Object.values(reports);
        const categoriesData = categories.map((report) => {
            const {
                id,
                name,
                reportTabCount
            } = report;

            const title = `${name} (${reportTabCount})`;

            return {
                id,
                title,
                isSelected: (id === reportId)
            };
        });
        const canCreateCategory =
            permissions.includes('REPORTING_EDITOR_CREATE_REPORT');
        const canCreateReport =
            permissions.includes('REPORTING_EDITOR_CREATE_REPORTTAB') ||
                ((tabs.length > 0) &&
                    permissions.includes('REPORTING_EDITOR_CLONE_REPORTTAB'));

        let visibleTabs = [];

        if (tabs && (tabs.length > 0)) {
            visibleTabs = tabs.filter((tab) => {
                return tab.display;
            });
        }

        let reportActions = [];

        if (categories.length) {
            reportActions = this.tabActionMenuObjects();
        }

        return (
            <div className={
                `viewport-rest ${(device.isComputer) ?
                    'computer' :
                    (device.isTablet) ?
                        'tablet' :
                        'mobile'}`
            }>
                <AppTitleBar
                    appIcon={ 'reporting_white' }
                    color={ program.cloudType }
                    text={
                        (device.isComputer) ?
                            'Pro Reporting' :
                            undefined
                    }
                    isTextButton={ false }
                    className={ 'app-title-bar' }
                >
                    { (Object.keys(reports).length > 0) &&
                        <div className="categories-dropdown-container">
                            <Dropdown
                                buttons={ this.generateReportActionButtons() }
                                className={ 'categories-dropdown' }
                                contrast={ 'high' }
                                data={ categoriesData }
                                select={ 'single' }
                                type={ 'layered' }
                                label={ 'Report Category' }
                                onValueChange={ this.selectReport }
                            />
                        </div>
                    }
                    { (visibleTabs.length > 0) &&
                        <ReportTabs />
                    }
                    { ((visibleTabs.length > 0) ||
                        (reportActions.length > 0)) &&
                        <div className="app-title-bar-icons">
                            { (visibleTabs.length > 0) &&
                                !showNewDashboard &&
                                <IconButton
                                    icon={
                                        filterBarVisible ?
                                            'filter_filled' :
                                            'filter'
                                    }
                                    buttonSize={ 'large' }
                                    contrast={ 'high' }
                                    hasAnimation={ false }
                                    disabled={ false }
                                    hasState={ false }
                                    active={ false }
                                    onClick={
                                        this.onToggleFilterBarVisibility
                                    }
                                    tooltip={ {
                                        text: 'Filter Criteria',
                                        direction: 5
                                    } }
                                />
                            }
                            { (reportActions.length > 0) &&
                                    <Dropdown
                                        type={ 'icon' }
                                        select={ 'none' }
                                        data={ reportActions }
                                        onValueChange={ this.onTabAction }
                                        contrast={ 'high' }
                                        internalProps={ {
                                            iconButton: {
                                                icon: 'kabob',
                                                buttonSize: 'large',
                                                hasState: false,
                                                tooltip: {
                                                    text: 'More',
                                                    direction: 5
                                                }
                                            }
                                        } }
                                        direction={ 5 }
                                    />
                            }
                        </div>
                    }
                </AppTitleBar>
                { reportsLoaded ?
                    ((Object.keys(reports).length > 0) ?
                        (tabsLoaded ?
                            ((visibleTabs.length > 0) ?
                                <div
                                    className="viewport-rest"
                                    style={
                                        ((visibleTabs.length > 0) &&
                                            !showNewDashboard &&
                                            !filterBarVisible &&
                                            filterBarHeight) ?
                                            {
                                                'marginTop': `-${filterBarHeight}px`
                                            } :
                                            null
                                    }
                                >
                                    <DashboardHolder
                                        hideIframe={
                                            (showTabsEditor ||
                                                categoryManagementVisible ||
                                                createCategoryVisible
                                            )
                                        }
                                    />
                                </div> :
                                <EmptyPage
                                    createHandler={
                                        (!hasTabLoadingError &&
                                            canCreateReport) ?
                                            this.showCreateNewReportModal :
                                            null
                                    }
                                    createMessage={ 'Create a Report' }
                                    mainMessage={ hasTabLoadingError ?
                                        'Error loading reports' :
                                        canCreateReport ?
                                            'No reports have been created yet' :
                                            'You have not been assigned any reports'
                                    }
                                    secondaryMessage={ hasTabLoadingError ?
                                        'Contact your advisor' :
                                        canCreateReport ?
                                            'Let\'s get you started' :
                                            'Contact your advisor'
                                    }
                                />
                            ) :
                            <div className="entity-loading-mask">
                                <h1>Loading your reports...</h1>
                            </div>) :
                        <EmptyPage
                            createHandler={
                                canCreateCategory ?
                                    this.createCategory :
                                    null
                            }
                            createMessage={ 'Create a Category' }
                            mainMessage={ canCreateCategory ?
                                'No categories have been created yet' :
                                'You have not been assigned any categories'
                            }
                            secondaryMessage={ canCreateCategory ?
                                'Let\'s get you started' :
                                'Contact Your Advisor'
                            }
                        />) :
                    <div className="entity-loading-mask">
                        <h1>Loading your categories...</h1>
                    </div> }
                <ManageReports
                    onClose={ this.hideTabsEditorModal }
                    open={ showTabsEditor }
                    onCreate={ this.showReportCreator }
                    reportId={ reportId }
                    reports={ reports }
                    tabs={ tabs || [] }
                ></ManageReports>
                <CloneReport
                    open={ showCloneReport }
                    onClose= { this.hideCloneReport }
                    reportToClone={ reportToClone }
                ></CloneReport>
                <CreateNewReport
                    open={ showCreateNewReport }
                    onClose={ this.hideCreateNewReport }
                ></CreateNewReport>
                { canCreateOrEditCategory && <AddOrEditCategory
                    goToManageCategories={ this.goToManageCategories }
                    open={ createCategoryVisible }
                    onClose={ this.hideCategoryCreator }
                    reportName={ reportName }
                    reportIdToEdit={ reportIdToEdit }
                    validateReportName={ this.validateReportName }
                /> }
                { canManageCategories && <ManageCategories
                    open={ categoryManagementVisible }
                    onClose={ this.hideCategoryManagement }
                    onCreate={ this.createCategory }
                    onEdit={ this.editCategory }
                /> }
            </div>
        );
    }
}

// eslint-disable-next-line arrow-body-style
const mapStateToProps = (state) => ({
    changes: state.report.tabChanges,
    detailsOfReports: state.report.detailsOfReports,
    device: state.screenSize.device,
    hasTabLoadingError: state.report.hasTabLoadingError,
    filterBarHeight: state.report.filterBarHeight,
    filterBarVisible: state.report.filterBarVisible,
    pathName: state.router.location.pathname,
    permissions: state.user.permissions,
    program: state.user.program,
    createCategoryVisible: state.report.createCategoryVisible,
    categoryManagementVisible: state.report.categoryManagementVisible,
    reports: state.report.reports,
    reportsLoaded: state.report.reportsLoaded,
    reportId: state.report.reportId,
    reportToClone: state.wizard.reportToClone,
    reportIdToEdit: state.report.reportIdToEdit,
    shareMyTabView: state.featureFlags.shareMyTabView,
    showCloneReport: state.wizard.showCloneReport,
    showCreateNewReport: state.wizard.showCreateNewReport,
    showNewDashboard: state.featureFlags.showNewDashboard,
    tabId: state.report.tabId,
    tabs: state.report.tabs,
    tabsLoaded: state.report.tabsLoaded
});

// eslint-disable-next-line arrow-body-style
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreators, dispatch)
});

MainTabs.propTypes = {
    actions: PropTypes.shape({
        fetchTabs: PropTypes.func.isRequired,
        fetchDetailsOfReports: PropTypes.func.isRequired,
        resetTabs: PropTypes.func.isRequired,
        updateCreateCategoryVisibility: PropTypes.func.isRequired,
        updateCategoryManagementVisibility: PropTypes.func.isRequired,
        updateDevice: PropTypes.func.isRequired,
        updateFilterBarVisibility: PropTypes.func.isRequired,
        updatePathName: PropTypes.func.isRequired,
        updateReportId: PropTypes.func.isRequired,
        updateReportIdToEdit: PropTypes.func.isRequired,
        updateShowCloneReport: PropTypes.func.isRequired,
        updateShowCreateNewReport: PropTypes.func.isRequired,
        updateTabId: PropTypes.func.isRequired
    }),
    categoryManagementVisible: PropTypes.bool.isRequired,
    createCategoryVisible: PropTypes.bool.isRequired,
    reportToClone: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
    }),
    detailsOfReports: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            status: PropTypes.oneOf([
                'ACTIVE',
                'ARCHIVED',
                'PENDING'
            ]).isRequired,
            source: PropTypes.shape({
                name: PropTypes.string.isRequired
            }).isRequired,
            reportTemplate: PropTypes.shape({
                name: PropTypes.string.isRequired
            }).isRequired,
            roles: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.string.isRequired
                })
            ).isRequired
        })
    ).isRequired,
    device: PropTypes.object.isRequired,
    changes: PropTypes.any,
    classes: PropTypes.object.isRequired,
    hasTabLoadingError: PropTypes.bool,
    filterBarHeight: PropTypes.number,
    filterBarVisible: PropTypes.bool,
    reports: PropTypes.object.isRequired,
    reportsLoaded: PropTypes.bool.isRequired,
    pathName: PropTypes.string.isRequired,
    reportId: PropTypes.string,
    reportIdToEdit: PropTypes.string,
    tabId: PropTypes.number.isRequired,
    tabs: PropTypes.object.isRequired,
    tabsLoaded: PropTypes.bool.isRequired,
    shareMyTabView: PropTypes.bool.isRequired,
    showCreateNewReport: PropTypes.bool.isRequired,
    showCloneReport: PropTypes.bool.isRequired,
    showNewDashboard: PropTypes.bool.isRequired,
    permissions: PropTypes.array.isRequired,
    program: PropTypes.object.isRequired,
    width: PropTypes.number
};

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(withResizeDetector(MainTabs));