import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actionCreators from '../../actions';
import { bindActionCreators } from 'redux';

import {
    DefaultButton,
    GenericModal,
    InputField,
    XIAnimation
} from '@inmoment/the-kitchen';

import './CloneReport.scss';

const whitespaceOnlyRegex = /^\s+$/;
const whitespaceRunRegex = /\s\s+/;
const leadingOrTrailingWhitespaceRegex = /^\s|\s$/;

function CloneReport(props) {
    const {
        actions: {
            cloneReport
        },
        isLoadingCreateReport,
        onClose,
        open,
        program,
        reportToClone,
        tabs
    } = props;

    const [disabled, setDisabled] = React.useState(true);
    const [reportName, setReportName] = React.useState(null);
    const [
        reportNameErrorStatus,
        setReportNameErrorStatus
    ] = React.useState(null);

    React.useEffect(() => {
        if (isLoadingCreateReport) {
            setDisabled(true);
        }
    }, [isLoadingCreateReport]);

    React.useEffect(() => {
        if (typeof(reportName) === 'string') {
            let error;

            if (reportName.length < 1) {
                error = 'Name must be at least 1 character long!';
            }
            else if (whitespaceOnlyRegex.test(reportName)) {
                error = 'Name must contain 1 or more characters and/or numbers!';
            }
            else if (whitespaceRunRegex.test(reportName)) {
                error = 'Name cannot contain more than 1 space in a row!';
            }
            else if (leadingOrTrailingWhitespaceRegex.test(reportName)) {
                error = 'Name cannot contain leading or trailing spaces!';
            }
            else if (reportName.length > 50) {
                error = 'Name exceeds maximum character limit!';
            }
            else if (tabs.some((tab) => { return tab.name === reportName; })) {
                error = 'Name must be unique!';
            }

            if (error) {
                setReportNameErrorStatus({
                    state: 'error',
                    text: error
                });
            }
            else {
                setReportNameErrorStatus(null);
            }
        }
    }, [reportName]);

    React.useEffect(() => {
        setDisabled(!reportName || reportNameErrorStatus);
    }, [
        reportName,
        reportNameErrorStatus
    ]);

    const reset = () => {
        setDisabled(true);
        setReportName(null);
        setReportNameErrorStatus(null);
    };

    const handleClose = () => {
        reset();
        onClose();
    };

    const handleSubmit = async () => {
        await cloneReport(reportName, reportToClone.id);
        handleClose();
    };

    return (
        <GenericModal
            isVisible={ open }
            isVisualModal={ true }
            onClose= { onClose }
        >
            <div className="clone-report-modal">
                { (isLoadingCreateReport) ?
                    <div className="create-report-dialog-loading">
                        <XIAnimation
                            animation={ `${program.cloudType}_loader` }
                            loop={ true }
                        />
                    </div> :
                    <div className="clone-report">
                        <span>Clone Report</span>
                        <InputField
                            placeholder={ 'New Report Name' }
                            value={ reportName }
                            status={ reportNameErrorStatus }
                            onChange={ (e) => {
                                setReportName(e.target.value);
                            } }
                            className={ 'clone-report-input' }
                        />
                        <div className="clone-report-footer">
                            <DefaultButton
                                color={ 'orca' }
                                fill={ 'text' }
                                text={ 'Cancel' }
                                hasShadows={ false }
                                onClick={ handleClose }
                            />
                            <DefaultButton
                                color={ 'orca' }
                                disabled= { disabled }
                                fill={ 'fill' }
                                text={ 'Clone' }
                                hasShadows={ true }
                                onClick={ handleSubmit }
                                className={ 'default-button-clone' }
                            />
                        </div>
                    </div>
                }
            </div>
        </GenericModal>
    );
}

// eslint-disable-next-line arrow-body-style
const mapStateToProps = (state) => ({
    isLoadingCreateReport: state.report.isLoadingCreateReport,
    program: state.user.program,
    tabs: state.report.tabs
});

// eslint-disable-next-line arrow-body-style
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreators, dispatch)
});

CloneReport.propTypes = {
    actions: PropTypes.shape({
        cloneReport: PropTypes.func.isRequired
    }).isRequired,
    isLoadingCreateReport: PropTypes.bool.isRequired,
    onClose: PropTypes.func,
    open: PropTypes.bool.isRequired,
    program: PropTypes.shape({
        cloudType: PropTypes.oneOf([
            'CUSTOMER',
            'EMPLOYEE',
            'MARKETING'
        ]).isRequired
    }).isRequired,
    reportToClone: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired
    }),
    tabs: PropTypes.array.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(CloneReport);
