import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import * as actionCreators from '../../actions';
import { bindActionCreators } from 'redux';

import * as Sentry from '@sentry/browser';

import {
    XIAnimation,
    XISVG
} from '@inmoment/the-kitchen';

import './DashboardHolder.scss';

const DASHBOARD_LOADED = 'dashboard:loaded';
const DASHBOARD_RUN_START = 'dashboard:run:start';
const DASHBOARD_RUN_COMPLETE = 'dashboard:run:complete';
const PAGE_PROPERTIES_CHANGED = 'page:properties:changed';

const FILTER_BAR_HEIGHT = 49;
const TITLE_BAR_HEIGHT = 50;

// 5 minutes in milliseconds
const TIMEOUT_MS = 300000;

function generateUniqueName(eventName) {
    return function (dashboard) {
        const dashboardId = dashboard.id;
        const dashboardFilters = JSON.stringify(dashboard['dashboard_filters']);

        return `${eventName}-${dashboardId}-${dashboardFilters}`;
    };
}
const generateUniqueStart = generateUniqueName(DASHBOARD_RUN_START);
const generateUniqueComplete = generateUniqueName(DASHBOARD_RUN_COMPLETE);

function DashboardHolder(props) {
    const {
        actions: {
            logDashboardLoadTime,
            saveFilters,
            tabViewed,
            updateAuthenticated,
            updateFilterBarHeight,
            updateTabFilters
        },
        hideIframe,
        permissions,
        program,
        reportsAuthenticated,
        reportId,
        tabId,
        tabs
    } = props;

    const dashboardRef = useRef(null);
    const dashboardHeight = useRef(null);
    const baseFilterBarHeight = useRef(TITLE_BAR_HEIGHT);

    const [embedUrl, setEmbedUrl] = useState(null);
    const [error, setError] = useState(false);
    const [iframeStyle, setIframeStyle] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [selectedTab, setSelectedTab] = useState(null);
    const [timeoutRef, setTimeoutRef] = useState(null);

    const postMessage = (message) => {
        if (dashboardRef && dashboardRef.current) {
            const dashboardsDomain = (window.ProReporting &&
                window.ProReporting.links &&
                window.ProReporting.links.dashboards) || '';
            message = JSON.stringify(message);
            dashboardRef.current.contentWindow.postMessage(
                message,
                dashboardsDomain
            );
        }
    };

    const updateFilters = (filters) => {
        const message = {
            type: 'dashboard:filters:update',
            filters
        };
        postMessage(message);
    };

    const runDashboard = () => {
        const message = { type: 'dashboard:run' };
        postMessage(message);
    };

    const setTabViewed = (tabId) => {
        tabViewed(tabId);
    };

    const onIframeLoaded = () => {
        if (!selectedTab) {
            return;
        }

        // user has now viewed the report, so update that it has been seen
        setTabViewed(selectedTab.id);

        // if we've already done SSO, or the reports haven't
        // been loaded from the server, do nothing
        if (reportsAuthenticated) {
            return;
        }

        // we've loaded the embeded url for the first time, which
        // means we've authenticated and done SSO
        updateAuthenticated(true);
    };

    const onMessage = (event) => {
        if (dashboardRef &&
            dashboardRef.current &&
            (event.source === dashboardRef.current.contentWindow)) {
            const dashboardsDomain = (window.ProReporting &&
                window.ProReporting.links &&
                window.ProReporting.links.dashboards) || '';

            if (event.origin === dashboardsDomain) {
                const message = JSON.parse(event.data);
                if (message.type === DASHBOARD_LOADED) {
                    const {
                        dashboard: {
                            dashboard_filters: dashboardFilters
                        }
                    } = message;

                    let newBaseFilterBarHeight = TITLE_BAR_HEIGHT;

                    if (dashboardFilters) {
                        newBaseFilterBarHeight += FILTER_BAR_HEIGHT;
                    }

                    setLoaded(true);
                    updateFilterBarHeight(newBaseFilterBarHeight);
                    baseFilterBarHeight.current = newBaseFilterBarHeight;
                }

                if (message.type === DASHBOARD_RUN_START) {
                    const uniqueMark = generateUniqueStart(message.dashboard);

                    // mark the Dashboard run start event
                    performance.mark(uniqueMark);

                    const {
                        dashboard: {
                            dashboard_filters: dashboardFilters
                        }
                    } = message;

                    let newBaseFilterBarHeight = TITLE_BAR_HEIGHT;

                    if (dashboardFilters) {
                        newBaseFilterBarHeight += FILTER_BAR_HEIGHT;
                    }

                    updateFilterBarHeight(newBaseFilterBarHeight);
                    baseFilterBarHeight.current = newBaseFilterBarHeight;
                }

                if (message.type === DASHBOARD_RUN_COMPLETE) {
                    const {
                        status,
                        dashboard: {
                            id: dashboardId,
                            dashboard_filters: updatedFilters
                        }
                    } = message;

                    if (status === 'stopped') {
                        const uniqueMark = generateUniqueStart(message.dashboard);
                        // clear mark
                        performance.clearMarks(uniqueMark);
                    }

                    const tab = tabs.find((tab) => {
                        // convert dashboardId to a string. This is only
                        // necessary for the user defined dashboards, the IDs
                        // of which are numbers instead of strings
                        return tab.dashboardId === `${dashboardId}`;
                    });

                    if (!tab) {
                        // tab not found
                        return;
                    }

                    const { id: tabId, filters: currentFilters } = tab;

                    const uniqueMark = generateUniqueStart(message.dashboard);
                    // get the latest Dashboard run start event mark
                    const runStart = performance
                        .getEntriesByName(uniqueMark, 'mark')
                        .pop();

                    if (runStart) {
                        const uniqueMeasure = generateUniqueComplete(
                            message.dashboard
                        );

                        // create a measure between the run start event and run
                        // complete event (now)
                        performance.measure(uniqueMeasure, uniqueMark);

                        // get the latest Dashboard run complete event measure
                        const runComplete = performance
                            .getEntriesByName(uniqueMeasure, 'measure')
                            .pop();

                        if (runComplete) {
                            // duration is in milliseconds, but the decimals
                            // can include precision up to the microsecond; we
                            // aren't interested in that, so get the floor of
                            // the duration
                            const loadTime = Math.floor(runComplete.duration);

                            // call the action with the load time in
                            // milliseconds and currently applied filters
                            logDashboardLoadTime(
                                reportId,
                                tabId,
                                loadTime,
                                updatedFilters
                            );

                            // clear measure
                            performance.clearMeasures(uniqueMeasure);
                        }

                        // clear mark
                        performance.clearMarks(uniqueMark);
                    }

                    if (
                        JSON.stringify(updatedFilters) !==
                            JSON.stringify(currentFilters)
                    ) {
                        updateTabFilters(tabId, updatedFilters);

                        if (
                            permissions.includes(
                                'REPORTING_VIEWER_SAVE_FILTERS'
                            )
                        ) {
                            saveFilters(reportId, tabId, updatedFilters);
                        }
                    }
                }

                if (message.type === PAGE_PROPERTIES_CHANGED) {
                    const {
                        height: updatedDashboardHeight
                    } = message;

                    if (!dashboardHeight.current) {
                        dashboardHeight.current = updatedDashboardHeight;
                    }
                    else if (updatedDashboardHeight > dashboardHeight.current) {
                        const updatedExpandedFilterBarHeight =
                            updatedDashboardHeight - dashboardHeight.current;

                        updateFilterBarHeight(baseFilterBarHeight.current +
                            updatedExpandedFilterBarHeight);
                    }
                    else if (updatedDashboardHeight === dashboardHeight.current) {
                        updateFilterBarHeight(baseFilterBarHeight.current);
                    }
                }
            }
        }
    };

    useEffect(() => {
        window.addEventListener('message', onMessage);

        return () => {
            window.removeEventListener('message', onMessage);
        };
    }, [tabs]);

    useEffect(() => {
        // clear our measures and marks if a new Report was selected
        performance.clearMeasures();
        performance.clearMarks();

        dashboardHeight.current = null;
        baseFilterBarHeight.current = TITLE_BAR_HEIGHT;

        setError(false);
        setLoaded(false);
        clearTimeout(timeoutRef);
        setTimeoutRef(null);

        const tabsArray = tabs && tabs.filter((tab) => {
            return tab.display;
        });
        const tab = tabsArray && tabsArray.find((tab) => {
            return tab.id === tabId;
        });

        if (tab) {
            // if we haven't authenticated (done SSO), then use the signed
            // embed url for the src; otherwise use the non-signed embed url
            setEmbedUrl(reportsAuthenticated ?
                tab.embedUrl :
                tab.signedEmbedUrl
            );
            setSelectedTab(tab);

            const timeoutId = setTimeout(() => {
                setError(true);
                // if a timeout occurs, log it
                Sentry.captureMessage(
                    `Timeout loading dashboard: ${ tab.dashboardId }`,
                    'error'
                );
            }, TIMEOUT_MS);
            setTimeoutRef(timeoutId);
        }
        else {
            setEmbedUrl(null);
            setError(true);
            setSelectedTab(null);
        }

        return () => {
            clearTimeout(timeoutRef);
            setTimeoutRef(null);
        };
    }, [tabId]);

    useEffect(() => {
        if (loaded) {
            clearTimeout(timeoutRef);
            setTimeoutRef(null);
        }
    }, [loaded]);

    useEffect(() => {
        if (!loaded || hideIframe) {
            setIframeStyle({
                'visibility': 'hidden'
            });
        }
        else {
            setIframeStyle(null);
        }
    }, [hideIframe, loaded]);

    return (
        <div className="dashboard-holder">
            {
                (error) ?
                    <div className="dashboard-error">
                        <div className="error-image">
                            <XISVG
                                svg="no"
                            />
                        </div>
                        <div className="kxi-text-large primary-text">
                            Oops...
                        </div>
                        <div className="kxi-text-small secondary-text">
                            Something went wrong while trying to run your report.
                        </div>
                    </div> :
                    <>
                        {
                            (!loaded) &&
                                <div className="dashboard-loader">
                                    <XIAnimation
                                        animation={
                                            `${program.cloudType}_loader`
                                        }
                                        loop={ true }
                                    />
                                </div>
                        }
                        <iframe
                            ref={ dashboardRef }
                            src={ embedUrl }
                            style={ iframeStyle }
                            onLoad={
                                onIframeLoaded
                            }
                        />
                    </>
            }
        </div>
    );
}

// eslint-disable-next-line arrow-body-style
const mapStateToProps = (state) => ({
    reportsAuthenticated: state.report.authenticated,
    permissions: state.user.permissions,
    program: state.user.program,
    reportId: state.report.reportId,
    tabId: state.report.tabId,
    tabs: state.report.tabs
});

// eslint-disable-next-line arrow-body-style
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreators, dispatch)
});

DashboardHolder.propTypes = {
    actions: PropTypes.shape({
        logDashboardLoadTime: PropTypes.func.isRequired,
        saveFilters: PropTypes.func.isRequired,
        tabViewed: PropTypes.func.isRequired,
        updateAuthenticated: PropTypes.func.isRequired,
        updateFilterBarHeight: PropTypes.func.isRequired,
        updateTabFilters: PropTypes.func.isRequired
    }),
    hideIframe: PropTypes.bool,
    permissions: PropTypes.array.isRequired,
    program: PropTypes.shape({
        cloudType: PropTypes.oneOf([
            'CUSTOMER',
            'EMPLOYEE',
            'MARKETING'
        ]).isRequired
    }).isRequired,
    reportsAuthenticated: PropTypes.bool,
    reportId: PropTypes.string.isRequired,
    tabId: PropTypes.string.isRequired,
    tabs: PropTypes.array.isRequired
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps)
)(DashboardHolder);
