import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { findDOMNode } from 'react-dom';
import * as actionCreators from '../../actions';
import { bindActionCreators } from 'redux';

import ReactScrollIntoViewIfNeeded from 'react-scroll-into-view-if-needed';

import CategoryDetail from '../CategoryDetail';
import DnDItem from '../DnD/Item';
import DnDList from '../DnD/List';

import {
    DefaultButton,
    Drawer,
    Dropdown,
    FAB,
    SearchBox,
    WarningPopup,
    XIAnimation
} from '@inmoment/the-kitchen';

import './ManageCategories.scss';

function ManageCategories(props) {
    const {
        actions: {
            fetchDetailsOfReports,
            setReportVisibility,
            updateAllReports,
            updateReportFilterStatus
        },
        detailsOfReports,
        onEdit,
        onClose,
        onCreate,
        open,
        permissions,
        program,
        reportFilterStatus,
        reportIdToEdit,
        useAppSettings
    } = props;

    const [activeReportDetails, setActiveReportDetails] = useState([]);
    const [categories, setCategories] = useState([]);
    const [categorySortOrders, setCategorySortOrders] = useState([]);
    const [isSavingOrders, setIsSavingOrders] = useState(false);
    const [ready, setReady] = useState(false);
    const [reportIdToArchive, setReportIdToArchive] = useState(null);
    const [searchText, setSearchText] = useState(null);
    const [warning, setWarning] = useState(undefined);

    const fetchData = async () => {
        try {
            setReady(false);
            await fetchDetailsOfReports();
            setReady(true);
        }
        catch (err) {
            //no-op
        }
    };

    useEffect(() => {
        // when the component initially loads
        if (!detailsOfReports) {
            fetchData();
        }
        else {
            setCategories(detailsOfReports);
        }
    }, []);

    useEffect(() => {
        // when the program switches
        fetchData();
    }, [program]);

    useEffect(() => {
        // when the detailsOfReports in reducer changes
        if (!detailsOfReports) {
            fetchData();
        }
        else {
            setCategories(detailsOfReports);
        }
    }, [detailsOfReports]);

    useEffect(() => {
        const details = categories.filter((report) => {
            return report.status === 'ACTIVE';
        }).sort((a, b) => {
            return (a.sortOrder > b.sortOrder) ? 1 : -1;
        });
        setActiveReportDetails(details);
    }, [categories]);

    const filterData = [
        {
            id: 'ALL',
            title: 'All',
            isSelected: (
                reportFilterStatus === 'ALL'
            )
        },
        {
            id: 'ACTIVE',
            title: 'Active',
            isSelected: (
                reportFilterStatus === 'ACTIVE'
            )
        },
        {
            id: 'ARCHIVED',
            title: 'Archived',
            isSelected: (
                reportFilterStatus === 'ARCHIVED'
            )
        },
        {
            id: 'PENDING',
            title: 'Pending',
            isSelected: (
                reportFilterStatus === 'PENDING'
            )
        }
    ];

    const showArchiveWarning = (reportId) => {
        setWarning('ARCHIVE');
        setReportIdToArchive(reportId);
    };

    const archiveReport = async () => {
        setReportVisibility(reportIdToArchive, 'ARCHIVED');
        setReportIdToArchive(null);
    };

    const onActivate = (id) => {
        setReportVisibility(id, 'ACTIVE');
    };

    const searchReport = (text) => {
        setSearchText(text);
    };

    const clearSearchReport = () => {
        setSearchText(null);
    };

    const displayReportDetail = (report = {}) => {
        const {
            id: reportId,
            name,
            source,
            status,
            reportTabs,
            reportTemplate,
            roles
        } = report;

        const dataSourceName = source ? source.name : 'n/a';
        const reportsCount = Array.isArray(reportTabs) ? reportTabs.length : 0;
        const rolesCount = Array.isArray(roles) ? roles.length : 0;
        const reportTemplateName = reportTemplate ? reportTemplate.name : 'n/a';
        const canUpdateReportStatus = reportTemplate?.uniqueId === 'custom';

        return (
            <ReactScrollIntoViewIfNeeded
                active={ (reportId === reportIdToEdit) }
            >
                <CategoryDetail
                    className="categories-list-item"
                    canReorder={ (status === 'ACTIVE') && (reportFilterStatus === 'ACTIVE') }
                    canUpdateStatus={ canUpdateReportStatus }
                    dataSource={ dataSourceName }
                    id={ reportId }
                    name={ name }
                    onActivate={ onActivate }
                    onArchive={ showArchiveWarning }
                    onEdit={ onEdit }
                    reportsCount={ reportsCount }
                    rolesCount={ rolesCount }
                    status={ status }
                    template={ reportTemplateName }
                />
            </ReactScrollIntoViewIfNeeded>
        );
    };

    const displayAllReportDetails = () => {
        if (!ready) {
            return null;
        }

        const searchRegExp = new RegExp(
            (searchText || '').replace(/[-[\]{}()*+?.\\^$|]/g, '\\$&'),
            'i'
        );
        const includedStatuses = [];

        if (reportFilterStatus === 'ALL') {
            includedStatuses.push('ACTIVE', 'ARCHIVED', 'PENDING');

            if (useAppSettings) {
                includedStatuses.push('DISABLED');
            }
        }
        else {
            includedStatuses.push(reportFilterStatus);
        }

        return (
            categories.reduce((list, report) => {
                const { name, status } = report;

                if (includedStatuses.includes(status) &&
                        searchRegExp.test(name)) {
                    list.push(displayReportDetail(report));
                }
                return list;
            }, [])
        );
    };

    const reorderReports = (props, monitor, component) => {
        if (!ready || !monitor || monitor.didDrop()) {
            return null;
        }
        const activeReports = categories.reduce((reports, report) => {
            if (report.status === 'ACTIVE') {
                reports.push({ id: report.id, sortOrder: report.sortOrder });
            }
            return reports;
        }, []);

        const availableSortOrders = activeReports.map((activeReport) => {
            return activeReport.sortOrder;
        });

        if (props.getItem) {
            const { report: draggedReport } = monitor.getItem();
            const { report: droppedOnReport } = props.getItem();

            if (draggedReport.id === droppedOnReport.id) {
                return null;
            }
            const reorderedReports = activeReports.filter((report) => {
                return report.id !== draggedReport.id;
            });
            const droppedOnIndex = reorderedReports.findIndex((report) => {
                return report.id === droppedOnReport.id;
            });
            // eslint-disable-next-line react/no-find-dom-node
            const componentNode = findDOMNode(component);
            const componentRect = componentNode.getBoundingClientRect();
            const midpoint = (componentRect.bottom - componentRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const dropPos = clientOffset.y - componentRect.top;

            if (dropPos <= midpoint) {
                reorderedReports.splice(droppedOnIndex, 0, {
                    id: draggedReport.id,
                    sortOrder: draggedReport.sortOrder
                });
            }
            else {
                reorderedReports.splice(droppedOnIndex + 1, 0, {
                    id: draggedReport.id,
                    sortOrder: draggedReport.sortOrder
                });
            }

            const result = reorderedReports.map((r, index) => {
                return { ...r, sortOrder: availableSortOrders[index] };
            });

            setCategorySortOrders(result);
            setCategories(
                [...categories].map((category) => {
                    const updatedReport = result.find((changedCategory) => {
                        return changedCategory.id === category.id;
                    });
                    if (updatedReport) {
                        return {
                            ...category,
                            sortOrder: updatedReport.sortOrder
                        };
                    }
                    return category;
                })
            );
        }
    };

    const saveReorder =  async () => {
        setIsSavingOrders(true);

        await updateAllReports(categorySortOrders);
        setCategorySortOrders([]);

        setIsSavingOrders(false);
    };

    const closeDrawer = () => {
        setCategories(detailsOfReports);
        setCategorySortOrders([]);
        setWarning(undefined);
        onClose();
    };

    const cancelReorder = () => {
        if (categorySortOrders.length > 0) {
            setWarning('CANCEL_REORDER');
        }
        else {
            onClose();
        }
    };

    const onProceedWarning = () => {
        if (warning === 'ARCHIVE') {
            archiveReport();
        }
        else if (warning === 'CANCEL_REORDER') {
            closeDrawer();
        }
        setWarning(undefined);
    };

    const onCancelWarning = () => {
        if (warning === 'ARCHIVE') {
            setReportIdToArchive(null);
        }
        setWarning(undefined);
    };

    return (
        <>
            <Drawer
                color={ program.cloudType }
                interceptClose={ true }
                open={ open }
                onClose={ cancelReorder }
            >
                <div slot="title">
                    Manage Categories
                </div>
                <div slot="body">
                    { (!ready || isSavingOrders) ?
                        <div className="loading-animation">
                            <XIAnimation
                                animation={ `${program.cloudType}_loader` }
                                loop={ true }
                            />
                        </div> :
                        <>
                            <div className="manage-categories-header">
                                <div className="header-left">
                                    <div className="description-title">
                                        Manage Report Categories
                                    </div>
                                    <div className="description-subtitle">
                                        Create, edit and delete report categories
                                    </div>
                                    <div className="description-subtitle">
                                        Customize the display order by dragging and dropping each card
                                    </div>
                                </div>
                                <div className="header-right">
                                    <span className="header-right-span">
                                        <Dropdown
                                            type={ 'pill' }
                                            data={ filterData }
                                            onValueChange= { (reportStatus) => {
                                                updateReportFilterStatus(reportStatus.id);
                                            } }
                                        />
                                        <span className="category-search">
                                            <SearchBox
                                                placeholder={ 'Search' }
                                                onCancelClick={ clearSearchReport }
                                                onChangeOverride={ searchReport }
                                            />
                                        </span>
                                    </span>
                                    { permissions.includes('REPORTING_EDITOR_CREATE_REPORT') &&
                                        <FAB
                                            buttonSize={ 'xlarge' }
                                            color={ program.cloudType }
                                            icon={ 'fab_plus' }
                                            onClick={ onCreate }
                                        />
                                    }
                                </div>
                            </div>
                            <div className="category-list">
                                { ((reportFilterStatus === 'ACTIVE') &&
                                    (activeReportDetails) &&
                                    !(searchText)) ?
                                    <DnDList
                                        className="categories-drag-list active-categories"
                                        onDrop={ reorderReports }
                                        dropFrom={ ['active-categories'] }
                                        dragFrom={ 'active-categories' }
                                    >
                                        { activeReportDetails.map((report, index) => {
                                            const getItem = () => {
                                                return {
                                                    report,
                                                    from: 'active-categories'
                                                };
                                            };
                                            // This is only what we need for
                                            // dragPreview, at least for Chrome
                                            const dragPreview = (<div></div>);
                                            return (
                                                <DnDItem
                                                    className="categories-drag-item"
                                                    key={ index }
                                                    getItem={ getItem }
                                                    dragPreview={ dragPreview }
                                                    onDrop={ reorderReports }
                                                    dragDisabled={
                                                        (activeReportDetails.length === 1)
                                                    }
                                                >
                                                    {
                                                        displayReportDetail(report)
                                                    }
                                                </DnDItem>
                                            );
                                        }) }
                                    </DnDList> :
                                    displayAllReportDetails()
                                }
                            </div>
                        </>
                    }
                </div>
                <div slot="footer">
                    <DefaultButton
                        color={ 'orca' }
                        fill={ 'text' }
                        text={ 'Cancel' }
                        hasShadows={ false }
                        onClick={ cancelReorder }
                    />
                    <DefaultButton
                        color={ 'orca' }
                        disabled= { categorySortOrders.length < 1 }
                        fill={ 'fill' }
                        text={ 'Save' }
                        hasShadows={ true }
                        onClick={ saveReorder }
                        className={ 'default-button-save' }
                    />
                </div>
            </Drawer>
            { (warning) &&
                <WarningPopup
                    isVisible={ warning }
                    title={ 'Are you sure?' }
                    subtitle={ warning === 'ARCHIVE' ?
                        'Your report category will no longer be live and will be sent to the \u201CArchived\u201D section.' :
                        'There are unsaved category order changes, do you want to continue?'
                    }
                    buttons={ {
                        proceed: {
                            text: 'Yes, I\u2019m sure.'
                        },
                        cancel: {
                            text: 'Cancel'
                        } }
                    }
                    onProceed={ onProceedWarning }
                    onCancel={ onCancelWarning }
                    onClose={ onCancelWarning }
                />
            }
        </>
    );
}

// eslint-disable-next-line arrow-body-style
const mapStateToProps = (state) => ({
    actions: PropTypes.shape({
        fetchDetailsOfReports: PropTypes.func.isRequired
    }),
    detailsOfReports: state.report.detailsOfReports,
    permissions: state.user.permissions,
    program: state.user.program,
    reportFilterStatus: state.report.reportFilterStatus,
    reportIdToEdit: state.report.reportIdToEdit,
    useAppSettings: state['featureFlags']['xi-pro-reporting-app-settings']
});

// eslint-disable-next-line arrow-body-style
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreators, dispatch)
});

ManageCategories.propTypes = {
    actions: PropTypes.shape({
        fetchDetailsOfReports: PropTypes.func.isRequired,
        setReportVisibility: PropTypes.func.isRequired,
        updateAllReports: PropTypes.func.isRequired,
        updateReportFilterStatus: PropTypes.func.isRequired
    }).isRequired,
    detailsOfReports: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            name: PropTypes.string.isRequired,
            status: PropTypes.oneOf([
                'ACTIVE',
                'ARCHIVED',
                'DISABLED',
                'PENDING'
            ]).isRequired,
            source: PropTypes.shape({
                name: PropTypes.string.isRequired
            }).isRequired,
            reportTemplate: PropTypes.shape({
                name: PropTypes.string.isRequired
            }).isRequired,
            roles: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.string.isRequired
                })
            ).isRequired
        })
    ).isRequired,
    getItem: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onCreate: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    program: PropTypes.shape({
        cloudType: PropTypes.oneOf([
            'CUSTOMER',
            'EMPLOYEE',
            'MARKETING'
        ]).isRequired
    }),
    reportFilterStatus: PropTypes.oneOf([
        'ALL',
        'ACTIVE',
        'ARCHIVED',
        'DISABLED',
        'PENDING'
    ]).isRequired,
    reportIdToEdit: PropTypes.string,
    useAppSettings: PropTypes.bool.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageCategories);
