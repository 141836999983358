import React from 'react';
import PropTypes from 'prop-types';
import { DropTarget } from 'react-dnd';

const dropFrom = (props) => { return props.dropFrom; };

const itemTarget = {
    drop(props, monitor, component) {
        if (props.onDrop) {
            return props.onDrop(props, monitor, component);
        }

        return undefined;
    }
};

// eslint-disable-next-line arrow-body-style
const connectTarget = (connect, monitor) => ({
    connectDropTarget: connect.dropTarget()
});

class DnDList extends React.Component {
    render() {
        const { children, connectDropTarget, dragFrom, dropFrom, ...props } = this.props;

        return connectDropTarget(
            <div { ...props }>
                { React.Children.map(children, (child) => {
                    return React.cloneElement(child, { dragFrom, dropFrom });
                }) }
            </div>
        );
    }
}

DnDList.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired,
    connectDropTarget: PropTypes.func.isRequired,
    dragFrom: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.string
    ]).isRequired,
    dropFrom: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.string),
        PropTypes.string
    ]).isRequired
};

// eslint-disable-next-line new-cap
export default DropTarget(dropFrom, itemTarget, connectTarget)(DnDList);
