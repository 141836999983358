import { UPDATE_SNACK_BAR, UPDATE_SHOW_SUCCESS } from '../constants/ActionTypes';

const initialState = {
    snackBar: {
        mode: 'error',
        text: '',
        timestamp: ''
    },
    success: {
        description: '',
        title: '',
        isVisible: false
    }
};

export default function alert(state = initialState, action) {
    switch (action.type) {
        case UPDATE_SNACK_BAR:
            return {
                ...state,
                snackBar: {
                    ...state.snackBar,
                    ...action.payload
                }
            };
        case UPDATE_SHOW_SUCCESS:
            return {
                ...state,
                success: {
                    ...state.success,
                    ...action.success
                }
            };
        default:
            return state;
    }
}
