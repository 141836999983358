/* eslint-disable no-use-before-define */
import { push } from 'connected-react-router';
import * as Sentry from '@sentry/browser';
import { v4 as uuidv4 } from 'uuid';

import { authenticatedFetch } from '@inmoment/react-auth';

import * as types from '../constants/ActionTypes';
import * as authTypes from '../constants/AuthActionTypes';

function redirectToHome(programId) {
    // TO-DO: Figure out what I need to do to get optional chaining support
    const home = window.ProReporting &&
        window.ProReporting.links &&
        window.ProReporting.links.home;

    if (home) {
        window.location.assign(`${home}/program/${programId}/home`);
    }
}

// eslint-disable-next-line arrow-body-style
export const updateAccessToken = (payload) => ({
    type: types.UPDATE_ACCESS_TOKEN,
    payload
});

function getValidTabId(tabs, tabId) {
    if ((!Array.isArray(tabs)) || (tabs.length === 0)) {
        // we were not given a valid array of tabs, return undefined
        return undefined;
    }

    // filter tabs to only those that are visible (display is true)
    // and then order them (so that the tab with the lowest value of
    // order is first)
    const visibleSortedTabs = tabs.filter((tab) => {
        return tab.display;
    }).sort((tabA, tabB) => {
        return (tabA.order - tabB.order);
    });

    // we prevent the user from hiding all of their tabs, but in case
    // something gets messed up, return an empty object if it is an
    // empty array and thus the first element is undefined.
    const defaultTab = visibleSortedTabs[0] || {};

    let validTab;

    if (tabId) {
        validTab = visibleSortedTabs.find((tab) => {
            return (tab.id === tabId);
        });
    }

    validTab = validTab || defaultTab;

    return validTab.id;
}

function fetchHelper(path, method = 'GET', data) {
    const requestId = uuidv4();

    const config = {
        method,
        headers: {
            'x-request-id': requestId
        }
    };

    Sentry.setTag('transaction_id', requestId);

    if (data) {
        config['headers']['Content-Type'] = 'application/json; charset=utf-8';
        config['body'] = JSON.stringify(data);
    }

    return authenticatedFetch(path, config);
}

export const editReport = (reportId, reportName, roleIds, reportTabs) => {
    return async (dispatch, getState) => {
        const { user: { programId } } = getState();
        const reportData = {
            name: reportName,
            roleIds,
            reportTabs
        };

        try {
            const response = await fetchHelper(
                `/programs/${programId}/reports/${reportId}`,
                'POST',
                reportData
            );

            if (!response) {
                dispatch(updateSnackBar('Error editing category', 'error'));
                return;
            }

            const report = await response.json();

            if (response.status >= 400) {
                dispatch(
                    updateSnackBar(
                        (report.error || response.statusText),
                        'error'
                    )
                );
            }
            else {
                await dispatch(fetchReports());
                await dispatch(fetchDetailsOfReports());
                dispatch(updateCreateCategoryVisibility(false));
                dispatch(updateReportFilterStatus(report.status));
                dispatch(updateCategoryManagementVisibility(true));
                dispatch(updateReportIdToEdit(null));
                dispatch(
                    updateShowSuccess(
                        'This can take a few minutes, so check back soon.',
                        'Awesome! Your Category has been edited.',
                        true
                    )
                );
            }
        }
        catch (err) {
            //no-op
        }
    };
};

export const updateAllReports = (reports) => {
    return async (dispatch, getState) => {
        const { user: { programId } } = getState();

        try {
            const response = await fetchHelper(
                `/programs/${programId}/reports/update-all`,
                'POST',
                reports
            );

            if (!response || (response.status >= 400)) {
                dispatch(updateSnackBar('Error updating categories', 'error'));
                return;
            }

            const updatedReports = await response.json();

            if (updatedReports.error) {
                dispatch(updateSnackBar(updatedReports.error, 'error'));
            }
            else {
                // TO-DO: See if we can use response from `update-all` instead
                // of calling `fetchReports`
                dispatch(fetchReports());
                dispatch(fetchDetailsOfReports());
            }
        }
        catch (err) {
            //no-op
        }
    };
};

export const createReport = (
    dataSourceId,
    reportName,
    roleIds,
    reportTemplateId,
    reportTabs,
    advancedOptions
) => {
    return async (dispatch, getState) => {
        const { user: { programId } } = getState();

        const reportData = {
            dataSourceId,
            name: reportName,
            roleIds,
            reportTemplateId,
            reportTabs,
            advancedOptions
        };

        try {
            const response = await fetchHelper(
                `/programs/${programId}/reports`,
                'POST',
                reportData
            );

            if (!response) {
                dispatch(updateSnackBar('Error creating category', 'error'));
                return;
            }

            const report = await response.json();

            if (response.status >= 400) {
                dispatch(
                    updateSnackBar(
                        (report.error || response.statusText),
                        'error'
                    )
                );
            }
            else {
                dispatch(updateReportIdToEdit(report.id));
                await dispatch(fetchReports());
                await dispatch(fetchDetailsOfReports());
                dispatch(updateCreateCategoryVisibility(false));
                dispatch(updateReportFilterStatus(report.status));
                dispatch(updateCategoryManagementVisibility(true));
                dispatch(updateReportIdToEdit(null));
                dispatch(
                    updateShowSuccess(
                        'Your new Category has been created. Go get \u2018em champ!',
                        'Finished!',
                        true
                    )
                );
            }
        }
        catch (err) {
            //no-op
        }
    };
};

export const setReportVisibility = (reportId, status) => {
    return async (dispatch, getState) => {
        const { user: { programId } } = getState();

        try {
            const response = await fetchHelper(
                `/programs/${programId}/reports/${reportId}`,
                'PATCH',
                { status }
            );

            if (!response || (response.status >= 400)) {
                const errorMessage = (status === 'ARCHIVED') ?
                    'Error archiving the category' :
                    'Error setting the category active';
                dispatch(updateSnackBar(errorMessage, 'error'));
                return;
            }

            const reports = await response.json();

            if (reports.error) {
                dispatch(updateSnackBar(reports.error, 'error'));
            }
            else {
                dispatch(fetchReports());
                dispatch(fetchDetailsOfReports());
            }
        }
        catch (err) {
            //no-op
        }
    };
};

export const fetchReportTemplates = () => {
    return async (dispatch, getState) => {
        const { user: { programId } } = getState();

        let reportTemplates = [];

        try {
            const response = await fetchHelper(
                `/programs/${programId}/report-templates`
            );

            if (response && (response.status < 400)) {
                reportTemplates = await response.json();
                reportTemplates = reportTemplates.map((template) => {
                    template.disabled = (template.uniqueId !== 'custom');
                    return template;
                });
            }
            else if (response.status > 400) {
                dispatch(updateSnackBar('Error fetching templates', 'error'));
            }
        }
        catch (err) {
            //no-op
        }
        return reportTemplates;
    };
};

export const fetchRoles = () => {
    return async (dispatch, getState) => {
        const { user: { programId } } = getState();
        let roles = [];

        try {
            const response = await fetchHelper(
                `/programs/${programId}/roles`
            );

            if (response && (response.status < 400)) {
                roles = await response.json();
            }
            else if (response.status > 404) {
                dispatch(updateSnackBar('Error fetching roles', 'error'));
            }
        }
        catch (err) {
            //no-op
        }
        return roles;
    };
};

export const fetchDataSources = () => {
    return async (dispatch, getState) => {
        const { user: { programId } } = getState();
        let dataSources = [];

        try {
            const response = await fetchHelper(
                `/programs/${programId}/data-sources`
            );

            if (response && (response.status < 400)) {
                dataSources = await response.json();
            }
            else if (response.status > 404) {
                dispatch(
                    updateSnackBar('Error fetching data sources', 'error')
                );
            }
        }
        catch (err) {
            //no-op
        }
        return dataSources;
    };
};

export const fetchReportDetailAndTabs = (reportId) => {
    return async (dispatch, getState) => {
        const { user: { programId } } = getState();
        let reportAndTabs = {};
        try {
            const response = await fetchHelper(
                `/programs/${programId}/reports/${reportId}`
            );

            if (response && (response.status < 400)) {
                reportAndTabs = await response.json();
            }
            else if (!response || (response.status !== 404)) {
                dispatch(
                    updateSnackBar(
                        'Error fetching category for editing',
                        'error'
                    )
                );
            }
        }
        catch (err) {
            //no-op
        }
        return reportAndTabs;
    };
};

export const fetchDetailsOfReports = () => {
    return async (dispatch, getState) => {
        const { user: { programId } } = getState();
        let detailsOfReports = [];
        try {
            const response = await fetchHelper(
                `/programs/${programId}/reports/list`
            );

            if (response && (response.status < 400)) {
                detailsOfReports = await response.json();
            }
            else if (!response || (response.status !== 404)) {
                dispatch(updateSnackBar('Error fetching categories', 'error'));
            }
            dispatch(updateDetailsOfReports(detailsOfReports));
        }
        catch (err) {
            //no-op
        }
    };
};

export const cloneReport = (reportName, tabId) => {
    return async (dispatch, getState) => {
        const { user: { programId }, report: { reportId } } = getState();

        const tabData = {
            name: reportName,
            id: tabId
        };

        try {
            dispatch(setLoadingCreateReport());

            const response = await fetchHelper(
                `/programs/${programId}/reports/${reportId}/report-tabs`,
                'POST',
                tabData
            );

            if (!response || (response.status >= 400)) {
                dispatch(updateSnackBar('Error cloning report', 'error'));
                return;
            }

            const tab = await response.json();

            if (tab.error) {
                dispatch(updateSnackBar(tab.error, 'error'));
            }
            else {
                dispatch(updateShowCloneReport(false, null));
                dispatch(pushTab(tab));
                dispatch(
                    updateShowSuccess(
                        'Your cloned Report has been created. Go get \u2018em champ!',
                        'Finished!',
                        true
                    )
                );
            }
        }
        catch (err) {
            //no-op
        }
        finally {
            dispatch(unsetLoadingCreateReport());
        }
    };
};

export const createLinkedReport = (name, dashboardId) => {
    return async (dispatch, getState) => {
        const {
            user: {
                programId
            },
            report: {
                reportId
            }
        } = getState();

        const data = {
            name,
            dashboardId
        };

        try {
            dispatch(setLoadingCreateReport());

            const response = await fetchHelper(
                `/programs/${programId}/reports/${reportId}/report-tabs`,
                'POST',
                data
            );

            if (!response || (response.status >= 400)) {
                dispatch(
                    updateSnackBar(`Error creating report "${name}"`, 'error')
                );
                return;
            }

            const tab = await response.json();

            if (tab.error) {
                dispatch(updateSnackBar(tab.error, 'error'));
            }
            else {
                dispatch(updateShowCreateNewReport(false));
                dispatch(pushTab(tab));
                dispatch(
                    updateShowSuccess(
                        'Your new Report has been created. Go get \u2018em champ!',
                        'Finished!',
                        true
                    )
                );
            }
        }
        catch (err) {
            // no-op
        }
        finally {
            dispatch(unsetLoadingCreateReport());
        }
    };
};

export const createTab = (name) => {
    return async (dispatch, getState) => {
        const { user: { programId }, report: { reportId } } = getState();

        const tabData = {
            name
        };

        try {
            dispatch(setLoadingCreateReport());

            const response = await fetchHelper(
                `/programs/${programId}/reports/${reportId}/report-tabs`,
                'POST',
                tabData
            );

            if (!response || (response.status >= 400)) {
                dispatch(updateSnackBar('Error creating report', 'error'));
                return;
            }

            const tab = await response.json();

            if (tab.error) {
                dispatch(updateSnackBar(tab.error, 'error'));
            }
            else {
                dispatch(updateShowCreateNewReport(false));
                dispatch(pushTab(tab));
                dispatch(
                    updateShowSuccess(
                        'Your new Report has been created. Go get \u2018em champ!',
                        'Finished!',
                        true
                    )
                );
            }
        }
        catch (err) {
            //no-op
        }
        finally {
            dispatch(unsetLoadingCreateReport());
        }
    };
};

export const logDashboardLoadTime = (reportId, tabId, loadTime, filters) => {
    return async (dispatch, getState) => {
        const { user: { programId } } = getState();

        const data = {
            loadTime,
            filters
        };

        try {
            await fetchHelper(
                `/programs/${programId}/reports/${reportId}/report-tabs/${tabId}/load-time`,
                'POST',
                data
            );
        }
        catch (err) {
            // no-op
        }
    };
};

export const saveFilters = (reportId, tabId, filters) => {
    return async (dispatch, getState) => {
        const { user: { programId } } = getState();

        const data = {
            filters
        };

        try {
            const response = await fetchHelper(
                `/programs/${programId}/reports/${reportId}/report-tabs/${tabId}/filters`,
                'POST',
                data
            );

            if (!response || (response.status >= 400)) {
                dispatch(updateSnackBar('Error saving filters', 'error'));
                return;
            }

            const updatedTab = await response.json();

            if (updatedTab.error) {
                dispatch(updateSnackBar(updatedTab.error, 'error'));
            }
            else {
                dispatch(
                    updateSnackBar('Your filters have been saved', 'default')
                );
            }
        }
        catch (err) {
            //no-op
        }
    };
};

export const keepSessionActive = () => {
    return async (dispatch) => {
        try {
            const response = await fetchHelper('/auth/ping');

            if (response && (response.status === 200)) {
                const updatedAccessToken = await response.json();

                dispatch(updateAccessToken(updatedAccessToken));
            }
        }
        catch (err) {
            //no-op
        }
    };
};

export const fetchReports = () => {
    return async (dispatch, getState) => {
        const { user: { programId } } = getState();

        if (!programId) {
            return;
        }

        try {
            const response = await fetchHelper(
                `/programs/${programId}/reports`
            );

            let reportsMap = {};

            if (response && response.status) {
                if (response.status === 200) {
                    // we got back Reports, build the Report maps
                    const reports = await response.json();
                    reportsMap = reports.reduce((map, report) => {
                        const { id: reportId } = report;
                        map[reportId] = report;
                        return map;
                    }, {});
                }
                else if (response.status === 403) {
                    // if the user does not have permission to view Reports in
                    // this Program, redirect them to the Home Page
                    redirectToHome(programId);
                }
                else if ((response.status >= 400) &&
                            (response.status !== 404)) {
                    // we got back an error message
                    dispatch(
                        updateSnackBar('Error fetching categories', 'error')
                    );
                }
            }

            dispatch(updateReports(reportsMap));
            dispatch(validateReportId());

            if (Object.keys(reportsMap).length > 0) {
                await dispatch(fetchTabs());
            }

            dispatch(updatePathName());
        }
        catch (err) {
            //no-op
        }
    };
};

// eslint-disable-next-line arrow-body-style
export const validateReportId = () => (dispatch, getState) => {
    const { report: { reportId, reports } } = getState();

    if (Object.keys(reports).length === 0) {
        dispatch(updateReportId(undefined));
    }
    else {
        const availableReportIds = Object.keys(reports);
        if ((!reportId) || (!availableReportIds.includes(reportId))) {
            dispatch(updateReportId(getDefaultReportId(reports)));
            return;
        }
    }
};

export const getDefaultReportId = (reports) => {
    if (Object.keys(reports).length === 0) {
        return undefined;
    }
    return Object.keys(reports)[0];
};

// eslint-disable-next-line arrow-body-style
export const updateReports = (reports) => ({
    type: types.UPDATE_REPORTS,
    reports
});

// eslint-disable-next-line arrow-body-style
export const updateReportId = (reportId) => ({
    type: types.UPDATE_REPORT_ID,
    reportId
});

// eslint-disable-next-line arrow-body-style
export const validateTabId = () => (dispatch, getState) => {
    const { report: { tabs, tabId } } = getState();

    dispatch(updateTabId(getValidTabId(tabs, tabId)));
};

export const fetchTabs = () => {
    return async (dispatch, getState) => {
        const { user: { programId }, report: { reportId } } = getState();

        try {
            const response = await fetchHelper(
                `/programs/${programId}/reports/${reportId}/report-tabs`
            );

            let tabs = [];
            if (response && (response.status < 400)) {
                tabs = await response.json();
                dispatch(updateTabLoadingError(false));
            }
            else if (response && (response.status !== 404)) {
                dispatch(updateSnackBar('Error fetching reports', 'error'));
                dispatch(updateTabLoadingError(true));
            }

            dispatch(resetTabs());
            dispatch(updateTabs(tabs));
            dispatch(validateTabId());
            dispatch(updatePathName());
        }
        catch (err) {
            //no-op
        }
    };
};

// eslint-disable-next-line arrow-body-style
export const updateTabLoadingError = (hasTabLoadingError) => ({
    type: types.UPDATE_TAB_LOADING_ERROR,
    hasTabLoadingError
});

// eslint-disable-next-line arrow-body-style
export const updateTabDisplay = (tabId, display) => ({
    type: types.UPDATE_TAB_DISPLAY,
    tabId,
    display
});

// eslint-disable-next-line arrow-body-style
export const updateTabChanges = (tabChanges) => ({
    type: types.UPDATE_TAB_CHANGES,
    tabChanges
});

export const tabViewed = (tabId) => {
    return async (dispatch, getState) => {
        const { user: { programId }, report: { reportId } } = getState();

        try {
            // TODO: seems more RESTful to POST to .../report-tab/${tabId}/viewed.
            const response = await fetchHelper(
                `/programs/${programId}/reports/${reportId}/report-tabs/viewed/${tabId}`,
                'PUT'
            );

            const tab = await response.json();

            if (response.status === 200) {
                const lastSeen = tab.lastSeen;
                dispatch(updateLastSeen(tabId, lastSeen));
            }
        }
        catch (err) {
            // no-op
        }
    };
};

export const saveTabChanges = (tabs) => {
    return async (dispatch, getState) => {
        try {
            const {
                user: {
                    programId
                },
                report: {
                    reportId,
                    tabId
                }
            } = getState();

            dispatch(updateTabs(tabs));
            dispatch(updateTabId(getValidTabId(tabs, tabId)));

            const tabChanges = tabs.map((tab) => {
                return {
                    id: tab.id,
                    name: tab.name,
                    order: tab.order,
                    display: tab.display
                };
            });

            const response = await fetchHelper(
                `/programs/${programId}/reports/${reportId}/report-tabs/update-all`,
                'POST',
                tabChanges
            );

            if (!response || (response.status >= 400)) {
                dispatch(
                    updateSnackBar('Error saving report changes', 'error')
                );
                return;
            }

            // show success message before fetching updated tabs
            dispatch(
                updateSnackBar('Report changes saved successfully', 'default')
            );
            // TODO: either have the response contain the JSON for the reordered tabs, or have an
            // endpoint to reload the tabs in the current report.  for now just reload all tabs.
            await dispatch(fetchTabs());
        }
        catch (err) {
            // network failure or fetch was prevented from completing the request
        }
    };
};

// eslint-disable-next-line arrow-body-style
export const logout = () => (dispatch) => {
    dispatch(push('/logout'));
};

// eslint-disable-next-line arrow-body-style
export const pushTab = (tab) => ({
    type: types.PUSH_TAB,
    tab
});

// eslint-disable-next-line arrow-body-style
export const setUserError = () => ({
    type: authTypes.SET_USER_ERROR
});

// eslint-disable-next-line arrow-body-style
export const updateAuthenticated = (authenticated) => ({
    type: types.UPDATE_AUTHENTICATED,
    authenticated
});

// eslint-disable-next-line arrow-body-style
export const updatePathName = () => (dispatch, getState) => {
    const { user: { programId }, report: { reportId, tabId } } = getState();
    let pathName = '/program/' + programId;

    if (reportId) {
        pathName += '/category/' + reportId;

        if (tabId) {
            pathName += '/report/' + tabId;
        }
    }
    dispatch(push(pathName));
};

// eslint-disable-next-line arrow-body-style
export const updateSentryTags = () => (dispatch, getState) => {
    const {
        user: {
            accountId,
            programId
        }
    } = getState();

    if (accountId && programId) {
        Sentry.setTag('account_id', accountId);
        Sentry.setTag('program_id', programId);
    }
};

// eslint-disable-next-line arrow-body-style
export const updateShowSuccess = (description, title, isVisible) => ({
    type: types.UPDATE_SHOW_SUCCESS,
    success: {
        description,
        title,
        isVisible
    }
});

// eslint-disable-next-line arrow-body-style
export const updateSnackBar = (text, mode) => ({
    type: types.UPDATE_SNACK_BAR,
    payload: {
        mode,
        text,
        timestamp: new Date().getTime().toString()
    }
});

// eslint-disable-next-line arrow-body-style
export const updateShowCreateNewReport = (open) => ({
    type: types.UPDATE_SHOW_CREATE_NEW_REPORT,
    open
});

// eslint-disable-next-line arrow-body-style
export const updateShowCloneReport = (open, reportToClone) => ({
    type: types.UPDATE_SHOW_CLONE_REPORT,
    open,
    reportToClone
});

// eslint-disable-next-line arrow-body-style
export const updateLastSeen = (tabId, lastSeen) => ({
    type: types.UPDATE_LAST_SEEN,
    tabId,
    lastSeen
});

// eslint-disable-next-line arrow-body-style
export const updateTabId = (id) => ({
    type: types.UPDATE_TAB_ID,
    id
});

// eslint-disable-next-line arrow-body-style
export const updateTabs = (tabs) => ({
    type: types.UPDATE_TABS,
    tabs
});

// eslint-disable-next-line arrow-body-style
export const updateTab = (reportId, tab) => ({
    type: types.UPDATE_TAB,
    reportId,
    tab
});

// eslint-disable-next-line arrow-body-style
export const updateUserInfo = (payload) => ({
    type: types.UPDATE_USER_INFO,
    payload
});

export const switchToProgram = (programId) => {
    return async function (dispatch) {
        dispatch(updateProgramId(programId));
        dispatch(resetReportId());
        dispatch(resetDetailsOfReports());
        dispatch(resetTabsAndTabId());
        dispatch(updateAuthenticated(false));
        await dispatch(fetchReports());
        dispatch(updateShowCreateNewReport(false));
        dispatch(updateShowCloneReport(false, null));
        dispatch(updatePathName());
    };
};

// eslint-disable-next-line arrow-body-style
export const updateByPathName = (programId, reportId, tabId) => (dispatch, getState) => {
    const { user: { programs } } = getState();

    // If programIdFromPath exists and is valid, use it, otherwise do nothing.
    const programIds = Object.values(programs).map((program) => {
        return program.id;
    });
    if (programId && programIds.includes(programId)) {
        dispatch(updateProgramId(programId));
        dispatch(updateSentryTags());
    }

    // reportId is required to be set before calling fetchReports(), because
    // fetchReports() always sets undefined reportId to be the first reportId
    // fetched. The validation of the reportId is inside the function
    // fetchReports().
    if (reportId) {
        dispatch(updateReportId(reportId));
    }

    //The validation of the tabId is inside the function fetchTabs().
    if (tabId) {
        dispatch(updateTabId(tabId));
    }
};

// eslint-disable-next-line arrow-body-style
export const updateProgramId = (programId) => ({
    type: types.UPDATE_PROGRAM_ID,
    programId
});

// eslint-disable-next-line arrow-body-style
export const updateFeatureFlags = (featureFlags) => ({
    type: types.UPDATE_FEATURE_FLAGS,
    featureFlags
});

// eslint-disable-next-line arrow-body-style
export const setLoadingCreateReport = () => ({
    type: types.SET_LOADING_CREATE_REPORT
});

// eslint-disable-next-line arrow-body-style
export const unsetLoadingCreateReport = () => ({
    type: types.UNSET_LOADING_CREATE_REPORT
});

// eslint-disable-next-line arrow-body-style
export const resetTabs = () => ({
    type: types.RESET_TABS
});

// eslint-disable-next-line arrow-body-style
export const resetTabId = () => (dispatch) => {
    dispatch(updateTabId(undefined));
};

// eslint-disable-next-line arrow-body-style
export const resetTabsAndTabId = () => (dispatch) => {
    dispatch(resetTabs());
    dispatch(resetTabId());
};

// eslint-disable-next-line arrow-body-style
export const resetReportId = () => (dispatch) => {
    dispatch(updateReportId(undefined));
};

// eslint-disable-next-line arrow-body-style
export const resetDetailsOfReports = () => (dispatch) => {
    dispatch(updateDetailsOfReports(undefined));
};

// eslint-disable-next-line arrow-body-style
export const updateTabFilters = (tabId, filters) => ({
    type: types.UPDATE_TAB_FILTERS,
    tabId,
    filters
});

// eslint-disable-next-line arrow-body-style
export const updateDetailsOfReports = (detailsOfReports) => ({
    type: types.UPDATE_DETAILS_OF_REPORTS,
    detailsOfReports
});

// eslint-disable-next-line arrow-body-style
export const updateCreateCategoryVisibility = (isVisible) => ({
    type: types.UPDATE_CREATE_CATEGORY_VISIBILITY,
    isVisible
});

// eslint-disable-next-line arrow-body-style
export const updateReportIdToEdit = (reportId) => ({
    type: types.UPDATE_REPORT_ID_TO_EDIT,
    reportId
});

// eslint-disable-next-line arrow-body-style
export const updateCategoryManagementVisibility = (isVisible) => ({
    type: types.UPDATE_CATEGORY_MANAGEMENT_VISIBILITY,
    isVisible
});

// eslint-disable-next-line arrow-body-style
export const updateReportFilterStatus = (reportFilterStatus) => ({
    type: types.UPDATE_REPORT_FILTER_STATUS,
    reportFilterStatus
});

// eslint-disable-next-line arrow-body-style
export const updateDevice = (device) => ({
    type: types.UPDATE_DEVICE,
    device
});

// eslint-disable-next-line arrow-body-style
export const updateFilterBarHeight = (height) => ({
    type: types.UPDATE_FILTER_BAR_HEIGHT,
    height
});

// eslint-disable-next-line arrow-body-style
export const updateFilterBarVisibility = (isVisible) => ({
    type: types.UPDATE_FILTER_BAR_VISIBILITY,
    isVisible
});
