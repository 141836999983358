import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { findDOMNode } from 'react-dom';
import * as actionCreators from '../../actions';
import { bindActionCreators } from 'redux';

import {
    DefaultButton,
    Drawer,
    Dropdown,
    FAB,
    SearchBox
} from '@inmoment/the-kitchen';

import ReportDetail from '../ReportDetail';
import DnDItem from '../DnD/Item';
import DnDList from '../DnD/List';
import './ManageReports.scss';

const byOrder = (tab1, tab2) => { return tab1.order - tab2.order; };

function ManageReports(props) {
    const {
        actions: {
            saveTabChanges,
            updateShowCloneReport,
            updateShowCreateNewReport,
            updateTabChanges
        },
        changes,
        onClose,
        open,
        permissions,
        program,
        reportId: categoryId,
        reports: categories,
        tabs
    } = props;

    const [selectedCategoryName, setSelectedCategoryName] = useState('');
    const [filter, setFilter] = useState(
        {
            nameRe: null,
            state: 'active'
        }
    );

    useEffect(() => {
        const initial = tabs.reduce((initial, tab, index) => {
            initial[tab.id] = { order: index, display: tab.display };

            return initial;
        }, {});

        // set the initial state for tab changes
        updateTabChanges(initial);
    }, [tabs]);

    useEffect(() => {
        if (categories && categories[categoryId]) {
            setSelectedCategoryName(categories[categoryId].name);
        }
        else {
            setSelectedCategoryName('');
        }
    }, [categories, categoryId]);

    const onNameUpdate = (tabId, name) => {
        updateTabChanges({
            ...changes,
            [tabId]: {
                ...changes[tabId],
                name
            }
        });
    };

    const updateSearchText = (text) => {
        text = text || null;
        const nameRe = text && new RegExp(
            text.replace(/[-[\]{}()*+?.\\^$|]/g, '\\$&'),
            'i'
        );

        setFilter({  ...filter, nameRe });
    };

    const handleResetNameFilter = () => {
        setFilter({  ...filter, nameRe: null });
    };

    const stateFilterChange = (status) => {
        setFilter({  ...filter, state: status });
    };

    const closeDrawer = () => {
        onClose();
        updateTabChanges();
    };

    const cancelChanges = () => {
        updateTabChanges();
        closeDrawer();
    };

    const saveChanges = () => {
        const modifiedTabs = [];
        tabs.forEach((tab) => {
            const change = changes[tab.id];
            modifiedTabs.push({ ...tab, ...change });
        });

        if (modifiedTabs.length) {
            saveTabChanges(modifiedTabs);
        }

        closeDrawer();
    };

    const onActivate = (tabId) => {
        updateTabChanges({
            ...changes,
            [tabId]: {
                ...changes[tabId],
                display: true
            }
        });
    };

    const onArchive = (tabId) => {
        updateTabChanges({
            ...changes,
            [tabId]: {
                ...changes[tabId],
                display: false
            }
        });
    };

    const onClone = (tabId) => {
        let reportToClone;

        const report = tabs.find((tab) => {
            return tab.id === tabId;
        });

        if (report) {
            reportToClone = {
                id: report.id,
                name: report.name
            };
            onClose();
            updateShowCloneReport(true, reportToClone);
        }
    };

    const onCreate = () => {
        onClose();
        updateShowCreateNewReport(true);
    };

    const reorderTabs = (props, monitor, component) => {
        if (!monitor || monitor.didDrop()) {
            return;
        }

        const { report: source } = monitor.getItem();
        let destination = undefined;

        // dropping on the list should add the tab at the bottom.
        // if (component instanceof DnDList) {
        // instanceof doesn't work for some reason.
        if (component.constructor.name === 'DnDList') {
            destination = {
                list: true,
                order: Object.keys(changes).length - 1
            };
        }
        // otherwise dropping on a list item.
        else if (props.getItem) {
            ({ report: destination } = props.getItem());
        }
        else {
            return;
        }

        // ignore dropping an item on itself.
        if (source.id === destination.id) {
            return;
        }

        let order = destination.order;
        if (!(destination.list)) {
            // to-do: find an alternative for findDOMNode
            // eslint-disable-next-line react/no-find-dom-node
            const componentRect = findDOMNode(component).getBoundingClientRect();
            const midpoint = (componentRect.bottom - componentRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const dropPos = clientOffset.y - componentRect.top;

            if ((source.order < destination.order) && (dropPos < midpoint)) {
                --order;
            }
            else if ((source.order > destination.order) && (dropPos > midpoint)) {
                ++order;
            }
        }

        // if nothing would have changed, do nothing.
        if (source.order === order) {
            return;
        }

        const minOrder = Math.min(source.order, order);
        const maxOrder = Math.max(source.order, order);
        const adjustment = (source.order < order) ? -1 : 1;
        const newChanges = {};

        for (const [tabId, change] of Object.entries(changes)) {
            if ((change.order < minOrder) || (change.order > maxOrder)) {
                continue;
            }
            else if (tabId === String(source.id)) {
                change.order = order;
            }
            else {
                change.order += adjustment;
            }
            newChanges[tabId] = change;
        }

        setTimeout(() => { updateTabChanges(newChanges); }, 0);
    };

    const disableSave = Object.values(changes).some((change) => {
        return (typeof(change.error) === 'string');
    });

    const {
        nameRe,
        state: stateFilter
    } = filter;
    const reports = [];

    tabs.forEach((tab) => {
        const change = changes[tab.id];
        tab = { ...tab, ...change };

        if (stateFilter) {
            if ((stateFilter === 'active') &&
                    (!tab.display)) {
                return;
            }
            else if ((stateFilter === 'archived') &&
                    (tab.display)) {
                return;
            }
        }

        if (nameRe && !(nameRe.test(tab.name))) {
            return;
        }

        reports.push(tab);
    });
    reports.sort(byOrder);

    return (
        <>
            <Drawer
                color={ program.cloudType }
                open={ open }
                onClose={ onClose }
            >
                <div slot="title">
                    Manage Reports
                    { (selectedCategoryName) ?
                        <>
                            <span className="title-span"> &gt; </span>
                            { selectedCategoryName }
                        </> :
                        null
                    }
                </div>
                <div slot="body">
                    <div className="manage-reports-header">
                        <div className="header-left">
                            <div className="description-title">
                                Manage your Reports
                            </div>
                            <div className="description-subtitle">
                                Configure the reports in this category
                            </div>
                        </div>
                        <div className="header-right">
                            <span className="header-right-span">
                                <Dropdown
                                    type={ 'pill' }
                                    data={
                                        [
                                            {
                                                id: 'active',
                                                title: 'Active',
                                                isSelected: (filter.state === 'active')
                                            },
                                            {
                                                id: 'archived',
                                                title: 'Archived',
                                                isSelected: (filter.state === 'archived')
                                            }
                                        ]
                                    }
                                    onValueChange= { (reportStatus) => {
                                        stateFilterChange(reportStatus.id);
                                    } }
                                />
                                <span className="report-search">
                                    <SearchBox
                                        placeholder={ 'Search' }
                                        onCancelClick={ handleResetNameFilter }
                                        onChangeOverride={ updateSearchText }
                                    />
                                </span>
                            </span>
                            { permissions.includes('REPORTING_EDITOR_CREATE_REPORTTAB') &&
                                <FAB
                                    buttonSize={ 'xlarge' }
                                    color={ program.cloudType }
                                    icon={ 'fab_plus' }
                                    onClick={ onCreate }
                                />
                            }
                        </div>
                    </div>
                    <div className="report-list">
                        <DnDList
                            className="reports-drag-list"
                            dragFrom={ 'reports-drag-list' }
                            dropFrom={ ['reports-drag-list'] }
                            onDrop={ reorderTabs }
                        >
                            { reports.map((report, index) => {
                                return (
                                    <DnDItem
                                        className="reports-drag-item"
                                        dragDisabled={
                                            (reports.length === 1)
                                        }
                                        dragPreview={ (<div></div>) }
                                        getItem={ () => {
                                            return {
                                                report,
                                                from: 'reports-drag-list'
                                            };
                                        } }
                                        key={ index }
                                        onDrop={ reorderTabs }
                                    >
                                        <ReportDetail
                                            className="report"
                                            id={ report.id }
                                            isOwner={ report.isOwner }
                                            lastOpened={ report.lastSeen }
                                            name={ report.name }
                                            onActivate={
                                                onActivate
                                            }
                                            onArchive={
                                                onArchive
                                            }
                                            onClone={
                                                onClone
                                            }
                                            onNameUpdate={
                                                onNameUpdate
                                            }
                                            runs={ report.numberOfViews }
                                            status={
                                                (report.display) ?
                                                    'active' :
                                                    'archived'
                                            }
                                            type={ report.tabType }
                                        />
                                    </DnDItem>
                                );
                            }) }
                        </DnDList>
                    </div>
                </div>
                <div slot="footer">
                    <DefaultButton
                        color={ 'orca' }
                        fill={ 'text' }
                        text={ 'Cancel' }
                        hasShadows={ false }
                        onClick={ cancelChanges }
                    />
                    <DefaultButton
                        color={ 'orca' }
                        disabled= { disableSave }
                        fill={ 'fill' }
                        text={ 'Save' }
                        hasShadows={ true }
                        onClick={ saveChanges }
                        className={ 'default-button-save' }
                    />
                </div>
            </Drawer>
        </>
    );
}

// eslint-disable-next-line arrow-body-style
const mapStateToProps = (state) => ({
    changes: state.report.tabChanges,
    permissions: state.user.permissions,
    program: state.user.program,
    reportId: state.report.reportId,
    reports: state.report.reports
});

// eslint-disable-next-line arrow-body-style
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreators, dispatch)
});

ManageReports.propTypes = {
    actions: PropTypes.shape({
        saveTabChanges: PropTypes.func.isRequired,
        updateShowCloneReport: PropTypes.func.isRequired,
        updateShowCreateNewReport: PropTypes.func.isRequired,
        updateTabChanges: PropTypes.func.isRequired
    }),
    changes: PropTypes.any,
    getItem: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
    program: PropTypes.object.isRequired,
    reportId: PropTypes.string,
    reports: PropTypes.object.isRequired,
    tabs: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageReports);
