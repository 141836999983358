import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { findDOMNode } from 'react-dom';
import * as actionCreators from '../../actions';
import { bindActionCreators } from 'redux';

import {
    DefaultButton,
    Drawer,
    Dropdown,
    HeadingButton,
    IconButton,
    InputField,
    XIAnimation
} from '@inmoment/the-kitchen';

import DnDItem from '../DnD/Item';
import DnDList from '../DnD/List';
import CreateCustomReportTab from '../CustomReportTab/Create';
import ManageCustomReportTab from '../CustomReportTab/Manage';

import './AddOrEditCategory.scss';

const idNumberRegex = /^[1-9][0-9]*$/;
const whitespaceOnlyRegex = /^\s+$/;
const whitespaceRunRegex = /\s\s+/;

function AddOrEditCategory(props) {
    const {
        actions: {
            createReport,
            editReport,
            fetchReportTemplates,
            fetchDataSources,
            fetchRoles,
            fetchReportDetailAndTabs
        },
        goToManageCategories,
        onClose,
        open,
        permissions,
        program,
        programId,
        reportName,
        reportIdToEdit,
        roles,
        validateReportName
    } = props;

    const [advancedOptions, setAdvancedOptions] = useState({});
    const [dataSourceId, setDataSourceId] = useState(null);
    const [dataSources, setDataSources] = useState([]);
    const [isCreatingOrEditingReport, setIsCreatingOrEditingReport] = useState(false);
    const [nameForReport, setNameForReport] = useState(reportName);
    const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
    const [ready, setReady] = useState(false);
    const [reportNameError, setReportNameError] = useState(null);
    const [reportTabs, setReportTabs] = useState([]);
    const [reportTemplates, setReportTemplates] = useState([]);
    const [reportTemplateId, setReportTemplateId] = useState('');
    const [roleIds, setRoleIds] = useState([roles[programId].id]);
    const [rolesInState, setRolesInState] = useState([]);

    const canEditReportDetails = !reportIdToEdit || reportTemplateId === 'custom';

    const fetchData = async () => {
        try {
            if (reportIdToEdit) {
                const reportToEdit = await fetchReportDetailAndTabs(reportIdToEdit);
                const tabs = reportToEdit.reportTabs || [];
                setReportTabs(
                    tabs.map((tab, index) => {
                        return Object.assign({
                            id: undefined,
                            dashboardId: '',
                            deleted: false,
                            display: true,
                            name: '',
                            order: index
                        }, tab);
                    })
                );

                const fetchedRoles = await fetchRoles();

                const reportName = reportToEdit.name;
                const dataSourceId = reportToEdit.source.id;
                const reportTemplateId = reportToEdit.reportTemplate.uniqueId;
                const roleIds = reportToEdit.roles.map((role) => {
                    return role.id;
                });
                const dataSourceName = reportToEdit.source.name;
                const reportTemplateName = reportToEdit.reportTemplate.name;

                const dataSources = [
                    {
                        id: dataSourceId,
                        name: dataSourceName
                    }
                ];
                const reportTemplates = [
                    {
                        id: reportTemplateId,
                        name: reportTemplateName
                    }
                ];

                setNameForReport(reportName);
                setDataSourceId(dataSourceId);
                setDataSources(dataSources);
                setReportTemplateId(reportTemplateId);
                setReportTemplates(reportTemplates);
                setRoleIds(roleIds);
                setRolesInState(fetchedRoles);
            }
            else {
                const reportTemplates = await fetchReportTemplates();
                const dataSources = await fetchDataSources();
                const fetchedRoles = await fetchRoles();

                setDataSources(dataSources);
                setReportTemplates(reportTemplates);
                setRolesInState(fetchedRoles);

                if (reportTemplates.length > 0) {
                    const selectedTemplate = reportTemplates.find((template) => {
                        return !template.disabled;
                    });
                    if (selectedTemplate) {
                        setReportTemplateId(selectedTemplate.id);
                    }
                }

                if (dataSources.length > 0) {
                    setDataSourceId(dataSources[0].id);
                }
            }

            setReady(true);
        }
        catch (err) {
            //no-op
        }
    };

    const reset = () => {
        setAdvancedOptions({});
        setDataSourceId(null);
        setDataSources([]);
        setIsCreatingOrEditingReport(false);
        setNameForReport(reportName);
        setShowAdvancedSettings(false);
        setReady(false);
        setReportNameError(null);
        setReportTabs([]);
        setReportTemplates([]);
        setReportTemplateId('');
        setRoleIds([roles[programId].id]);
        setRolesInState([]);
    };

    useEffect(() => {
        // when this component initially loads
        fetchData();
    }, []);

    useEffect(() => {
        // when this component re-opens
        if (open) {
            fetchData();
        }
    }, [open]);

    useEffect(() => {
        const nameError = validateReportName(nameForReport, reportIdToEdit);
        setReportNameError(nameError);
    }, [nameForReport]);

    const createNewReport = async () => {
        const tabs = reportTabs.filter((tab) => {
            return !tab.deleted;
        }).map((tab) => {
            return {
                dashboardId: tab.dashboardId,
                display: tab.display,
                name: tab.name,
                order: tab.order
            };
        });

        setIsCreatingOrEditingReport(true);

        await createReport(
            dataSourceId,
            nameForReport,
            roleIds,
            reportTemplateId,
            tabs,
            advancedOptions
        );

        setIsCreatingOrEditingReport(false);
    };

    const updateRoleIds = (roles) => {
        const roleIds = roles.map((role) => { return role.id; });
        setRoleIds(roleIds);
    };

    const updateReport = async () => {
        const reportTabsToUpdate = reportTabs.map((tab) => {
            return {
                id: tab.id,
                dashboardId: tab.dashboardId,
                name: tab.name,
                display: tab.display,
                order: tab.order,
                deleted: tab.deleted
            };
        });
        setIsCreatingOrEditingReport(true);

        await editReport(
            reportIdToEdit,
            nameForReport,
            roleIds,
            reportTabsToUpdate
        );

        setIsCreatingOrEditingReport(false);
    };

    const isValid = () => {
        if (!ready) {
            return false;
        }

        const validReportName = !reportNameError &&
            (typeof(nameForReport) === 'string') &&
            (nameForReport.length > 0);
        const validReportTemplate = Boolean(reportTemplateId);
        const validDataSource = Boolean(dataSourceId);
        const validRoles = roleIds.length > 0;
        const validTabs = reportTabs.every((tab) => {
            const {
                dashboardId,
                dashboardIdError,
                deleted,
                name,
                tabNameError
            } = tab;

            if (deleted) {
                return true;
            }

            const hasError = (tabNameError || dashboardIdError);
            const hasEmptyValue = (
                ((typeof(name) !== 'string') || (name.length === 0)) ||
                (
                    (typeof(dashboardId) !== 'string') ||
                    (dashboardId.length === 0)
                )
            );

            return !(hasError || hasEmptyValue);
        });

        return validReportName &&
            validReportTemplate &&
            validDataSource &&
            validRoles &&
            validTabs;
    };

    const addNewTab = () => {
        const tabs = reportTabs;
        const order = tabs.length;
        tabs.push({
            id: undefined,
            dashboardId: '',
            dashboardIdError: null,
            deleted: false,
            display: true,
            name: '',
            order,
            tabNameError: null
        });
        setReportTabs([...tabs]);
    };

    const validateDashboardId = (order, dashboardId) => {
        const tabs = reportTabs.filter((tab) => {
            return (!tab.deleted && (tab.order !== order));
        });

        let dashboardIdError;

        if (!idNumberRegex.test(dashboardId)) {
            dashboardIdError = 'The ID Number you entered is not valid. Must be a number greater than 0. For example: 104';
        }
        else if (tabs.some((tab) => {
            return tab.dashboardId === dashboardId;
        })) {
            dashboardIdError = 'ID Number must be unique';
        }
        else {
            dashboardIdError = null;
        }

        return dashboardIdError;
    };

    const validateTabName = (order, tabName) => {
        const tabs = reportTabs.filter((tab) => {
            return (!tab.deleted && (tab.order !== order));
        });

        let tabNameError;

        if (tabName.length < 1) {
            tabNameError = 'Name must be at least 1 character long';
        }
        else if (whitespaceOnlyRegex.test(tabName)) {
            tabNameError = 'Name must contain 1 or more characters or numbers';
        }
        else if (whitespaceRunRegex.test(tabName)) {
            tabNameError = 'Name cannot contain more than 1 space in a row';
        }
        else if (tabName.length > 50) {
            tabNameError = 'Name exceeds maximum character limit';
        }
        else if (tabs.some((tab) => { return tab.name === tabName; })) {
            tabNameError = 'Name must be unique';
        }
        else {
            tabNameError = null;
        }

        return tabNameError;
    };

    const updateReportTab = (order, updatedValue) => {
        const hasNameProperty = Object.prototype.hasOwnProperty.call(
            updatedValue,
            'name'
        );
        const hasDashboardIdProperty = Object.prototype.hasOwnProperty.call(
            updatedValue,
            'dashboardId'
        );

        if (hasNameProperty) {
            const tabName = updatedValue.name;
            updatedValue.tabNameError = validateTabName(order, tabName);
        }
        else if (hasDashboardIdProperty) {
            const dashboardId = updatedValue.dashboardId;
            updatedValue.dashboardIdError = validateDashboardId(order, dashboardId);
        }

        setReportTabs(reportTabs.map((tab) => {
            if (tab.order === order) {
                return {
                    ...tab,
                    ...updatedValue
                };
            }
            return tab;
        }));
    };

    const reorderCustomTabs = (props, monitor, component) => {
        if (!ready || !monitor || monitor.didDrop()) {
            return null;
        }

        const { tab: draggedTab } = monitor.getItem();

        const reorderedTabs = reportTabs.filter((tab) => {
            return tab.order !== draggedTab.order;
        });

        if (props.getItem) {
            const { tab: droppedOnTab } = props.getItem();
            if (draggedTab.order === droppedOnTab.order) {
                return null;
            }
            const droppedOnIndex = droppedOnTab.order;
            // eslint-disable-next-line react/no-find-dom-node
            const componentRect = findDOMNode(component).getBoundingClientRect();
            const midpoint = (componentRect.bottom - componentRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const dropPos = clientOffset.y - componentRect.top;

            if (dropPos <= midpoint) {
                reorderedTabs.splice(droppedOnIndex, 0, draggedTab);
            }
            else if (dropPos > midpoint) {
                reorderedTabs.splice(droppedOnIndex + 1, 0, draggedTab);
            }
        }

        reorderedTabs.map((tab, index) => {
            tab.order = index;
            return tab;
        });

        setReportTabs(reorderedTabs);
    };

    const addNewTabDisabled = reportTabs.some((tab) => {
        return (!tab.deleted &&
            ((tab.name === '') || (tab.dashboardId === '')));
    });

    const createTabsSection = (hasOneVisible) => {
        reportTabs.sort((a, b) => { return (a.order > b.order) ? 1 : -1; });

        const tabList = reportTabs.reduce((list, tab) => {
            if (!tab.deleted) {
                const getItem = () => {
                    return { tab, from: 'custom-reports-list' };
                };
                const { dashboardIdError, order, tabNameError } = tab;
                const disableVisibleToggle = (hasOneVisible && tab.display);

                list.push(
                    <DnDItem className="custom-report-item"
                        key={ order }
                        getItem={ getItem }
                        dragPreview={ <div></div> }
                        onDrop={ reorderCustomTabs }
                        dragDisabled={ (reportTabs.length === 1) }
                    >
                        <div key={ order } className="custom-report">
                            <CreateCustomReportTab
                                canEditDetails={ canEditReportDetails }
                                dashboardId={ tab.dashboardId }
                                dashboardIdError={ dashboardIdError }
                                disableVisibleToggle={ disableVisibleToggle }
                                display={ tab.display }
                                reportTabs= { reportTabs }
                                tabId={ tab.id }
                                tabName={ tab.name }
                                tabNameError={ tabNameError }
                                order={ order }
                                onUpdate={ updateReportTab }
                            />
                        </div>
                    </DnDItem>
                );
            }
            return list;
        }, []);

        return (
            <div>
                <div className="manage-reports-section">
                    <div className="custom-report-title">
                        <div className="custom-report-name">
                            Report Name
                        </div>
                        <div className="custom-report-id">
                            ID Number
                        </div>
                    </div>
                    <div className="custom-reports">
                        <DnDList
                            className="custom-reports-list"
                            onDrop={ reorderCustomTabs }
                            dropFrom="custom-reports-list"
                            dragFrom="custom-reports-list"
                        >
                            { tabList }
                        </DnDList>
                    </div>
                    {
                        canEditReportDetails
                            && <div className="add-custom-report-button">
                                <DefaultButton
                                    color={ 'orca' }
                                    fill={ 'text' }
                                    text={ 'Add a report' }
                                    buttonSize={ 'normal' }
                                    iconLeft={ 'add_circle' }
                                    disabled={ addNewTabDisabled }
                                    hasShadows={ false }
                                    onClick={ addNewTab }
                                />
                            </div>
                    }
                </div>
            </div>
        );
    };

    const editTabsSection = (hasOneVisible) => {
        reportTabs.sort((a, b) => { return (a.order > b.order) ? 1 : -1; });

        const tabList = reportTabs.map((tab) => {
            const getItem = () => {
                return { tab, from: 'custom-reports-list' };
            };
            const { display, name, order, tabNameError } = tab;
            const disableVisibleToggle = (hasOneVisible && tab.display);

            return (
                <DnDItem
                    className="custom-report-item"
                    key={ order }
                    getItem={ getItem }
                    dragPreview={ <div></div> }
                    onDrop={ reorderCustomTabs }
                    dragDisabled={ (reportTabs.length === 1) }
                >
                    <div key={ order } className="custom-report">
                        <ManageCustomReportTab
                            canEditDetails={ canEditReportDetails }
                            disableVisibleToggle={ disableVisibleToggle }
                            display={ display }
                            tabName={ name }
                            order={ order }
                            onUpdate={ updateReportTab }
                            tabNameError={ tabNameError }
                        />
                    </div>
                </DnDItem>
            );
        });

        return (
            <div>
                <DnDList
                    className="custom-reports-list"
                    onDrop={ reorderCustomTabs }
                    dropFrom="custom-reports-list"
                    dragFrom="custom-reports-list"
                >
                    { tabList }
                </DnDList>
            </div>
        );
    };

    const submit = () => {
        if (reportIdToEdit) {
            updateReport();
        }
        else {
            createNewReport();
        }
        reset();
    };

    const onCancel = () => {
        onClose();
        reset();
    };

    const goBack = () => {
        reset();
        goToManageCategories();
    };

    const canCreateReportTabs = permissions.includes(
        'REPORTING_EDITOR_CREATE_REPORT'
    );
    const canOnlyEditReportTabs = (!canCreateReportTabs &&
            permissions.includes('REPORTING_EDITOR_EDIT_REPORT'));

    const hasOneVisible = (reportTabs.filter((tab) => {
        return !tab.deleted && tab.display;
    }).length === 1);

    let dataSourcesMenuObjects = [];

    if (ready) {
        if (reportIdToEdit) {
            dataSourcesMenuObjects = dataSources.map((dataSource) => {
                return {
                    id: dataSource.id,
                    title: dataSource.name,
                    isSelected: (dataSource.id === dataSourceId)
                };
            });
        }
        else {
            const selectedTemplate = reportTemplates.find((template) => {
                return template.id === reportTemplateId;
            });
            if (selectedTemplate) {
                const { type: dataSourceTypes } = selectedTemplate;
                const dataSourcesByTemplate = dataSources.filter((dataSource) => {
                    const { dataSourceType } = dataSource;
                    return dataSourceTypes.includes(dataSourceType);
                });
                dataSourcesMenuObjects = dataSourcesByTemplate.map((dataSource) => {
                    return {
                        id: dataSource.id,
                        title: dataSource.name,
                        isSelected: (dataSource.id === dataSourceId)
                    };
                });
            }
        }
    }

    const manageReportButton = () => {
        setShowAdvancedSettings(true);
        if (reportTabs.length < 1) {
            addNewTab();
        }
    };

    const reportTemplatesMenuObjects = reportTemplates.map((template) => {
        return {
            id: template.id,
            isSelected: template.id === reportTemplateId,
            title: template.name,
            disabled: template.disabled
        };
    });

    const roleMenuObjects = rolesInState.map((role) => {
        const isSelected = roleIds.includes(role.id);
        return { id: role.id, title: role.label, isSelected };
    });

    const selectedRolesText = (roleIds.length === 1) ?
        `${roleIds.length} Role Selected` :
        `${roleIds.length} Roles Selected`;

    return (
        <Drawer
            color={ program.cloudType }
            interceptClose={ true }
            onClose={ onCancel }
            open={ open }
        >
            <div slot="title">
                Manage Categories
                <span className="title-span"> &gt; </span>
                { reportIdToEdit ? 'Edit Category' : 'Add Category' }
            </div>
            <div slot="body">
                { (!ready || isCreatingOrEditingReport)  ?
                    <div className="add-or-edit-dialog-loading">
                        <XIAnimation
                            animation={ `${program.cloudType}_loader` }
                            loop={ true }
                        />
                    </div> :
                    <div>
                        <div className="add-or-edit-top">
                            <div className="title">
                                <IconButton
                                    icon={ 'arrow_left' }
                                    hasState={ false }
                                    onClick={ goBack }
                                />
                                { reportIdToEdit ?
                                    'Edit Category' :
                                    'Add Category'
                                }
                            </div>
                            <div className="subtitle">
                                { reportIdToEdit ?
                                    'Make edits to your category' :
                                    'Create a new category by providing a name and optional description'
                                }
                            </div>
                        </div>
                        <div className="add-or-edit-row">
                            <div className="description">
                                <div className="title">Name</div>
                                <div className="subtitle">Give this category a name</div>
                            </div>
                            <div className="name-input">
                                <InputField
                                    value={ nameForReport }
                                    status={ null }
                                    onChange={ (e) => {
                                        setNameForReport(e.target.value);
                                    } }
                                    disabled={ !canEditReportDetails }
                                />
                            </div>
                        </div>
                        <div className="add-or-edit-row">
                            <div className="description">
                                <div className="title">Template</div>
                                <div className="subtitle">Select a template</div>
                            </div>
                            <div className="xi-dropdown">
                                <Dropdown
                                    disabled={ Boolean(reportIdToEdit) || !ready }
                                    color={ program.cloudType }
                                    data={ reportTemplatesMenuObjects }
                                    placeholder={ 'Select template' }
                                    stretch={ true }
                                    onValueChange={ (reportTemplate) => {
                                        setReportTemplateId(reportTemplate.id);
                                    } }
                                />
                            </div>
                        </div>
                        <div className="add-or-edit-row">
                            <div className="description">
                                <div className="title">Data Source</div>
                                <div className="subtitle">Select the data source you want the report created from</div>
                            </div>
                            <div className="xi-dropdown">
                                <Dropdown
                                    color={ program.cloudType }
                                    disabled={ Boolean(reportIdToEdit) || !ready }
                                    data={ dataSourcesMenuObjects }
                                    placeholder={ 'Select Data Source' }
                                    stretch={ true }
                                    onValueChange={ (dataSource) => {
                                        setDataSourceId(dataSource.id);
                                    } }
                                />
                            </div>
                        </div>
                        <div className="add-or-edit-row">
                            <div className="description">
                                <div className="title">Share</div>
                                <div className="subtitle">Select which roles will have access to this category</div>
                            </div>
                            <div className="xi-dropdown">
                                <Dropdown
                                    data={ roleMenuObjects }
                                    disabled={ !ready }
                                    select={ 'multi' }
                                    stretch={ true }
                                    placeholder={ selectedRolesText }
                                    onValueChange={ updateRoleIds }
                                />
                            </div>
                        </div>
                        <div className="add-or-edit-row">
                            { (!showAdvancedSettings) ?
                                <div className="description">
                                    <HeadingButton
                                        title={
                                            'Manage reports in this category'
                                        }
                                        color={ program.cloudType }
                                        onClick={ manageReportButton }
                                    />
                                </div> :
                                <div className="description">
                                    <div className="title">Create Reports</div>
                                    <div className="subtitle">
                                        Give your reports a name and ID
                                    </div>
                                </div>
                            }
                            { showAdvancedSettings &&
                                (reportTabs.length >= 1) &&
                                canCreateReportTabs &&
                                createTabsSection(hasOneVisible)
                            }
                            { showAdvancedSettings &&
                                (reportTabs.length >= 1) &&
                                canOnlyEditReportTabs &&
                                reportTabs &&
                                editTabsSection(hasOneVisible)
                            }
                        </div>
                    </div>
                }
            </div>
            <div slot="footer">
                <DefaultButton
                    color={ 'orca' }
                    fill={ 'text' }
                    text={ 'Cancel' }
                    hasShadows={ false }
                    onClick={ onCancel }
                />
                <DefaultButton
                    color={ 'orca' }
                    disabled= { !isValid() }
                    fill={ 'fill' }
                    text={ reportIdToEdit ? 'Update' : 'Save' }
                    hasShadows={ true }
                    onClick={ submit }
                    className={ 'default-button-save' }
                />
            </div>
        </Drawer>
    );
}

// eslint-disable-next-line arrow-body-style
const mapStateToProps = (state) => ({
    permissions: state.user.permissions,
    program: state.user.program,
    programId: state.user.programId,
    roles: state.user.roles
});

// eslint-disable-next-line arrow-body-style
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreators, dispatch)
});

AddOrEditCategory.propTypes = {
    actions: PropTypes.shape({
        createReport: PropTypes.func.isRequired,
        editReport: PropTypes.func.isRequired,
        fetchDataSources: PropTypes.func.isRequired,
        fetchReportDetailAndTabs: PropTypes.func.isRequired,
        fetchReportTemplates: PropTypes.func.isRequired,
        fetchRoles: PropTypes.func.isRequired
    }),
    close: PropTypes.func.isRequired,
    getItem: PropTypes.func.isRequired,
    goToManageCategories: PropTypes.func.isRequired,
    onClose: PropTypes.bool.isRequired,
    open: PropTypes.bool.isRequired,
    permissions: PropTypes.array.isRequired,
    program: PropTypes.object.isRequired,
    programId: PropTypes.string.isRequired,
    reportIdToEdit: PropTypes.string,
    reportName: PropTypes.string.isRequired,
    roles: PropTypes.object.isRequired,
    validateReportName: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(AddOrEditCategory);
