import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { SnackBar } from '@inmoment/the-kitchen';

import './AlertSnackbar.scss';

class AlertSnackbar extends React.Component {
    render() {
        const { mode, text, timestamp } = this.props;

        return (
            <div className="snackbar-container">
                <SnackBar
                    autoDismiss={ true }
                    id={ timestamp }
                    mode={ mode }
                    text= { text }
                />
            </div>
        );
    }
}

// eslint-disable-next-line arrow-body-style
const mapStateToProps = (state) => ({
    mode: state.alert.snackBar.mode,
    text: state.alert.snackBar.text,
    timestamp: state.alert.snackBar.timestamp
});

AlertSnackbar.propTypes = {
    mode: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    timestamp: PropTypes.string.isRequired
};

export default connect(mapStateToProps)(AlertSnackbar);
