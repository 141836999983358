import React from 'react';
import PropTypes from 'prop-types';

import {
    DefaultButton
} from '@inmoment/the-kitchen';

import './EmptyPage.scss';

class EmptyPage extends React.Component {
    render() {
        const { createHandler, createMessage, mainMessage, secondaryMessage } = this.props;

        return (
            <div className='viewport-rest empty-page-body'>
                <div className="kxi-text-center kxi-text-xxlarge"
                    style={ {
                        marginTop: '180px'
                    } }
                >
                    { mainMessage }
                </div>
                <div className="kxi-text-center kxi-text-large"
                    style={ {
                        marginTop: '12px'
                    } }
                >
                    { secondaryMessage }
                </div>
                { Boolean(createHandler) &&
                    <div className="create-entity-button">
                        <DefaultButton
                            fill={ 'fill' }
                            text={ createMessage }
                            onClick={ createHandler }
                        />
                    </div>
                }
            </div>
        );
    }
}

EmptyPage.propTypes = {
    createHandler: PropTypes.func,
    createMessage: PropTypes.string,
    mainMessage: PropTypes.string.isRequired,
    secondaryMessage: PropTypes.string.isRequired
};

export default EmptyPage;