import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    DefaultButton,
    Dropdown,
    GenericModal,
    InputField
} from '@inmoment/the-kitchen';

import './ReportDetail.scss';

const whitespaceOnlyRegex = /^\s+$/;
const whitespaceRunRegex = /\s\s+/;
const leadingOrTrailingWhitespaceRegex = /^\s|\s$/;

function formatDate(dateISOString) {
    // we were not passed a string, assume the Report has not been viewed
    if (typeof(dateISOString) !== 'string') {
        return '---';
    }

    const dateObj = new Date(dateISOString);

    // we were passed an invalid date-time string, assume the Report has not
    // been viewed
    if (isNaN(dateObj.valueOf())) {
        return '---';
    }

    return [
        String(dateObj.getMonth() + 1).padStart(2, '0'),
        String(dateObj.getDate()).padStart(2, '0'),
        String(dateObj.getFullYear()).padStart(4, '0')
    ].join('/');
}

function formatType(reportType) {
    const systemTypes = ['CUSTOM', 'TEMPLATE'];
    const userTypes = ['DIY', 'SHARED_DIY'];

    if (systemTypes.includes(reportType)) {
        return 'System';
    }
    else if (userTypes.includes(reportType)) {
        return 'User';
    }
    else {
        return 'Unknown';
    }
}

function ReportDetail(props) {
    const {
        className,
        id,
        isOwner,
        lastOpened,
        name,
        onActivate,
        onArchive,
        onClone,
        onNameUpdate,
        permissions,
        runs,
        status,
        tabs,
        type
    } = props;

    const [error, setError] = React.useState(null);
    const [nameForReport, setNameForReport] = React.useState(name);
    const [showRename, setShowRename] = React.useState(false);

    const actions = [];

    if (isOwner) {
        actions.push({
            id: 'rename',
            title: 'Rename Report'
        });
    }

    if (permissions.includes('REPORTING_EDITOR_CLONE_REPORTTAB')) {
        actions.push({
            id: 'clone',
            title: 'Clone Report'
        });
    }

    if (status === 'active') {
        actions.push({
            id: 'archive',
            title: 'Archive Report'
        });
    }
    else if (status === 'archived') {
        actions.push({
            id: 'activate',
            title: 'Activate Report'
        });
    }

    const closeRename = () => {
        setShowRename(false);
    };

    const handleSubmitRename = () => {
        onNameUpdate(id, nameForReport);
        closeRename();
    };

    const validatName = (reportName) => {
        if (typeof(reportName) === 'string') {
            if (reportName.length < 1) {
                setError({
                    state: 'error',
                    text: 'Name must be at least 1 character long!'
                });
            }
            else if (whitespaceOnlyRegex.test(reportName)) {
                setError({
                    state: 'error',
                    text: 'Name must contain 1 or more characters and/or numbers!'
                });
            }
            else if (whitespaceRunRegex.test(reportName)) {
                setError({
                    state: 'error',
                    text: 'Name cannot contain more than 1 space in a row!'
                });
            }
            else if (leadingOrTrailingWhitespaceRegex.test(reportName)) {
                setError({
                    state: 'error',
                    text: 'Name cannot contain leading or trailing spaces!'
                });
            }
            else if (reportName.length > 50) {
                setError({
                    state: 'error',
                    text: 'Name exceeds maximum character limit!'
                });
            }
            else if (tabs.some((tab) => {
                return ((tab.id !== id) &&
                            (tab.name === reportName));
            })) {
                setError({
                    state: 'error',
                    text: 'Name must be unique!'
                });
            }
            else {
                setError(null);
            }
        }
    };

    const updateName = (reportName) => {
        setNameForReport(reportName);
        validatName(reportName);
    };

    const onSelect = (action) => {
        const { id: actionId } = action;

        if (actionId === 'rename') {
            setShowRename(true);
        }
        else if (actionId === 'clone') {
            onClone(id);
        }
        else if (actionId === 'activate') {
            onActivate(id);
        }
        else if (actionId === 'archive') {
            onArchive(id);
        }
    };

    return (
        <>
            <div className={ `report-detail-wrapper ${className || ''}` }>
                <div className="report-detail-title">
                    { name }
                </div>
                <div className="report-detail-main">
                    <div className="report-detail-data">
                        <div className="kxi-text-xsmall">
                        Type:
                        </div>
                        <div className="kxi-text-xsmall">
                            { formatType(type) }
                        </div>
                    </div>
                    <div className="report-detail-data">
                        <div className="kxi-text-xsmall">
                        Report Runs:
                        </div>
                        <div className="kxi-text-xsmall">
                            { `${runs}` }
                        </div>
                    </div>
                    <div className="report-detail-data">
                        <div className="kxi-text-xsmall">
                        Last Opened:
                        </div>
                        <div className="kxi-text-xsmall">
                            { formatDate(lastOpened) }
                        </div>
                    </div>
                </div>
                <Dropdown
                    type={ 'icon' }
                    color={ 'orca' }
                    select={ 'none' }
                    data={ actions }
                    internalProps={ {
                        iconButton: {
                            icon: 'kabob',
                            hasAnimation: false
                        }
                    } }
                    onValueChange={ onSelect }
                />
            </div>
            { showRename &&
               <GenericModal
                   isVisible={ showRename }
                   isVisualModal={ true }
                   onClose={ closeRename }
               >
                   <div className="rename-report-modal">
                       <div className="rename-report">
                           <span>Rename Report</span>
                           <InputField
                               placeholder={ 'Report Name' }
                               value={ name }
                               status={ error }
                               onChange={ (e) => {
                                   updateName(e.target.value);
                               } }
                               className={ 'rename-report-input' }
                           />
                           <div className="rename-report-footer">
                               <DefaultButton
                                   color={ 'orca' }
                                   fill={ 'text' }
                                   text={ 'Cancel' }
                                   hasShadows={ false }
                                   onClick={ closeRename }
                               />
                               <DefaultButton
                                   color={ 'orca' }
                                   disabled= { error }
                                   fill={ 'fill' }
                                   text={ 'Rename' }
                                   hasShadows={ true }
                                   onClick={ handleSubmitRename }
                                   className={ 'default-button-clone' }
                               />
                           </div>
                       </div>
                   </div>
               </GenericModal>
            }
        </>

    );
}

// eslint-disable-next-line arrow-body-style
const mapStateToProps = (state) => ({
    permissions: state.user.permissions,
    program: state.user.program,
    tabs: state.report.tabs
});

ReportDetail.propTypes = {
    className: PropTypes.string,
    errorMessage: PropTypes.string,
    id: PropTypes.string.isRequired,
    isOwner: PropTypes.bool.isRequired,
    lastOpened: PropTypes.string,
    name: PropTypes.string.isRequired,
    onArchive: PropTypes.func.isRequired,
    onActivate: PropTypes.func.isRequired,
    onClone: PropTypes.func.isRequired,
    onNameUpdate: PropTypes.func.isRequired,
    permissions: PropTypes.array.isRequired,
    program: PropTypes.object.isRequired,
    runs: PropTypes.number.isRequired,
    status: PropTypes.oneOf(['active', 'archived']).isRequired,
    type: PropTypes.string.isRequired,
    tabs: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default connect(mapStateToProps)(ReportDetail);
