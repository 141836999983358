import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as FullStory from '@fullstory/browser';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import * as actionCreators from './actions';
import { bindActionCreators } from 'redux';
import { useIntercom } from 'react-use-intercom';
import { endSession, getUser, login } from '@inmoment/react-auth';
import FeatureFlagContainer from '@inmoment/react-components/FeatureFlagContainer';
import { XIAnimation } from '@inmoment/the-kitchen';
import { Route, Switch } from 'react-router-dom';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import Logout from './components/Logout';
import Report from './components/Report';

function App({
    accountId,
    actions: {
        keepSessionActive,
        updateFeatureFlags,
        updateUserInfo
    },
    user: {
        authId,
        email,
        firstName,
        lastName,
        name,
        userId,
        userLocale
    }
}) {
    const [ready, setReady] = useState(false);
    const {
        boot: bootIntercom,
        update: updateIntercom
    } = useIntercom();
    const {
        location: {
            pathname
        },
        ProReporting = {}
    } = window;
    const { environment = '', featureFlag = {} } = ProReporting;
    const { clientId, flags = [] } = featureFlag || {};

    useEffect(() => {
        if (pathname === '/end_session') {
            endSession();
        }
    }, [pathname]);

    useEffect(() => {
        async function loadUserInfo() {
            try {
                const result = await getUser();

                if (result) {
                    updateUserInfo(result);
                }
                else {
                    Sentry.captureMessage(
                        'No user found; redirecting to login',
                        'info'
                    );
                    login();
                }
            }
            catch (err) {
                // if an error occurs, log it and retry
                Sentry.captureMessage(
                    `Error fetching user information: ${
                        err
                    }; redirecting to login`,
                    'error'
                );
                login();
            }
        }

        if (!ready) {
            bootIntercom({
                hideDefaultLauncher: true
            });

            loadUserInfo();
        }
        else {
            const bodyClassList = document.body.classList;
            bodyClassList.remove('loading');
        }
    }, [bootIntercom, ready, updateUserInfo]);

    useEffect(() => {
        if (authId) {
            setReady(true);

            // identify the user with Intercom
            updateIntercom({
                email: email,
                name: `${firstName} ${lastName}`
            });

            // identify the user with FullStory
            FullStory.identify(authId, {
                app: 'reporting',
                environment,
                email,
                familyName: lastName,
                givenName: firstName,
                name,
                locale: userLocale,
                id: authId
            });

            // identify the user with Sentry
            Sentry.setUser({
                id: userId,
                email
            });
            Sentry.setTag('locale', userLocale);
        }
    }, [
        authId,
        email,
        firstName,
        lastName,
        name,
        userId,
        userLocale,
        updateIntercom
    ]);

    useEffect(() => {
        const keepAliveIntervalId = setInterval(
            keepSessionActive,
            60000
        );

        return () => {
            clearInterval(keepAliveIntervalId);
        };
    }, [keepSessionActive]);

    return (
        (pathname === '/end_session') ?
            <div></div> :
            (pathname === '/logout') ?
                <div className="viewport">
                    <Logout />
                </div> :
                (ready) ?
                    <div className="viewport">
                        <FeatureFlagContainer
                            clientId={ clientId }
                            featureFlags={ flags }
                            updateFeatureFlagsCallback={ updateFeatureFlags }
                            user={ {
                                key: email,
                                email,
                                custom: {
                                    accountId
                                }
                            } }
                        >
                            <div className="viewport">
                                <Switch>
                                    <Route
                                        exact
                                        path='/logout'
                                        component={ Logout }
                                    />
                                    <Route
                                        path='/program/:programId/category/:reportId/report/:tabId'
                                        component={ Report }
                                    />
                                    <Route
                                        path='/program/:programId/category/:reportId'
                                        component={ Report }
                                    />
                                    <Route
                                        path='/program/:programId'
                                        component={ Report }
                                    />
                                    <Route
                                        path='/programs/:programId/reports/:reportId/tabs/:tabId'
                                        component={ Report }
                                    />
                                    <Route path='/' component={ Report } />
                                </Switch>
                            </div>
                        </FeatureFlagContainer>
                    </div> :
                    <div className="viewport loading">
                        <XIAnimation
                            animation={ 'orca_loader' }
                            loop={ true }
                        />
                    </div>
    );
}

// eslint-disable-next-line arrow-body-style
const mapStateToProps = (state) => ({
    accountId: state.user.accountId,
    user: state.user.user
});

// eslint-disable-next-line arrow-body-style
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreators, dispatch)
});

App.propTypes = {
    accountId: PropTypes.string,
    actions: PropTypes.shape({
        keepSessionActive: PropTypes.func.isRequired,
        updateFeatureFlags: PropTypes.func.isRequired,
        updateUserInfo: PropTypes.func.isRequired
    }).isRequired,
    user: PropTypes.shape({
        authId: PropTypes.string.isRequired,
        email: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        name: PropTypes.string,
        userId: PropTypes.string,
        userLocale: PropTypes.string.isRequired
    })
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DragDropContext(HTML5Backend)(App)); // eslint-disable-line new-cap
