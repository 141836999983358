import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { connect } from 'react-redux';
import * as actionCreators from '../../actions';
import { bindActionCreators } from 'redux';
import { useIntercom } from 'react-use-intercom';

import AlertModal from '../AlertModal';
import AlertSnackbar from '../AlertSnackbar';
import Main from '../Main';
import TopBar from '../TopBar';

import './Report.scss';

function Report({
    actions: {
        fetchReports,
        logout,
        switchToProgram,
        updateByPathName
    },
    match: {
        params: {
            programId: pathProgramId,
            reportId: pathReportId,
            tabId: pathTabId
        }
    },
    program,
    programs,
    programId,
    user: {
        accessToken,
        authId,
        userLocale
    }
}) {
    let assetsBaseUrl;
    let homeUrl;
    const {
        update: updateIntercom
    } = useIntercom();

    if (window.ProReporting && window.ProReporting.links) {
        assetsBaseUrl = window.ProReporting.links.assets || undefined;
        homeUrl = window.ProReporting.links.home || '';
    }

    useEffect(() => {
        async function loadCategories() {
            try {
                fetchReports();
            }
            catch (err) {
                Sentry.captureMessage(`Error displaying a Report: ${err}`);
            }
        }

        loadCategories();
    }, [fetchReports]);

    useEffect(() => {
        updateByPathName(pathProgramId, pathReportId, pathTabId);
        // update Intercom on page change, setting last_request_at to
        // the current time in seconds
        updateIntercom({
            'last_request_at': Math.floor(Date.now() / 1000)
        });
    }, [
        pathProgramId,
        pathReportId,
        pathTabId,
        updateIntercom,
        updateByPathName
    ]);

    useEffect(() => {
        // Setting the class on the body instead of this div so that it can
        // apply to any modals and popup panels also.
        const bodyClassList = document.body.classList;
        bodyClassList.remove('cx', 'mx', 'ex');

        const cloudType = program && program.cloudType;
        const cloudTypeClass = {
            'MARKETING': 'mx',
            'CUSTOMER': 'cx',
            'EMPLOYEE': 'ex'
        }[cloudType] || 'cx';
        cloudTypeClass && bodyClassList.add(cloudTypeClass);

        if (assetsBaseUrl) {
            let faviconName;

            switch (cloudType) {
                case 'CUSTOMER':
                    faviconName = 'favicon-cx-blue.svg';
                    break;
                case 'EMPLOYEE':
                    faviconName = 'favicon-ex-purple.svg';
                    break;
                case 'MARKETING':
                    faviconName = 'favicon-mx-green.svg';
                    break;
                default:
                    faviconName = 'favicon-orca.svg';
            }

            const favicon = document.getElementById('favicon');
            favicon.href = `${
                assetsBaseUrl
            }/assets/inmoment-logo/favicons/${
                faviconName
            }`;
        }
    }, [assetsBaseUrl, program]);

    return (
        <TopBar
            actions={ {
                logout,
                switchToProgram
            } }
            accessToken={ accessToken }
            authId={ authId }
            homeUrl={ homeUrl }
            programId={ programId }
            programs={ programs }
            userLocale={ userLocale }
        >
            <div className="viewport report-app-container">
                <AlertModal />
                <AlertSnackbar />
                <Main />
            </div>
        </TopBar>
    );
}

// eslint-disable-next-line arrow-body-style
const mapStateToProps = (state) => ({
    programId: state.user.programId,
    program: state.user.program,
    programs: state.user.programs,
    roles: state.user.roles,
    user: state.user.user
});

// eslint-disable-next-line arrow-body-style
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(actionCreators, dispatch)
});

Report.propTypes = {
    actions: PropTypes.shape({
        fetchReports: PropTypes.func.isRequired,
        logout: PropTypes.func.isRequired,
        switchToProgram: PropTypes.func.isRequired,
        updateByPathName: PropTypes.func.isRequired
    }),
    match: PropTypes.shape({
        params: PropTypes.shape({
            programId: PropTypes.string,
            reportId: PropTypes.string,
            tabId: PropTypes.string
        })
    }),
    programId: PropTypes.string.isRequired,
    program: PropTypes.shape({
        id: PropTypes.string,
        cloudType: PropTypes.string
    }),
    programs: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            account: PropTypes.shape({
                id: PropTypes.string.isRequired
            }).isRequired,
            selectable: PropTypes.bool.isRequired
        }).isRequired
    ),
    user: PropTypes.shape({
        accessToken: PropTypes.string.isRequired,
        authId: PropTypes.string.isRequired,
        userLocale: PropTypes.string.isRequired
    })
};

export default connect(mapStateToProps, mapDispatchToProps)(Report);
